import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 90%;
  right: 30px;

  display: flex;
  flex-direction: row;
`;

export const Button = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid gray;
  background: ${props =>
    props.type === 'primary' ? '#4a7e9e' : 'rgba(255, 255, 255, 0.85)'};

  & > div {
    height: max-content;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: ${props =>
      props.type === 'primary' ? 'white' : 'rgba(0, 0, 0, 0.4)'};
    font-size: 18px;
  }
`;
