import styled from "styled-components";
import { MEDIA_QUERY } from "../constants";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;

  @media ${MEDIA_QUERY.mobile} {
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-areas:
      "player"
      "configurator";

    & > div:nth-child(1) {
      grid-area: configurator;
    }

    & > div:nth-child(2) {
      grid-area: player;
    }
  }

  @media ${MEDIA_QUERY.tabletP} {
    display: grid;
    grid-template-columns: max-content auto;
  }
`;
