export const deviceSizes = {
  mobile: '599px',
  tabletP: '600px',
  tabletL: '900px',
  desktop: '1200px',
};

export const MEDIA_QUERY = {
  mobile: `(max-width: ${deviceSizes.mobile})`,
  tabletP: `(min-width: ${deviceSizes.tabletP})`,
  tabletL: `(min-width: ${deviceSizes.tabletL})`,
  desktop: `(min-width: ${deviceSizes.tabletL})`,
};

export const MEDIA_QUERIES = {
  mobile: { minDeviceWidth: deviceSizes.mobile },
  tabletP: { minDeviceWidth: deviceSizes.tabletP },
  tabletL: { minDeviceWidth: deviceSizes.tabletL },
  desktop: { minDeviceWidth: deviceSizes.desktop },
};

export const DOOR_ANIMATION = {
  duration: 3000, // Length of anmation in ms
  frames: 150, // How many frames pe animation
  distance: 52.5, // Non linear movement function
};

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
export const THREEKIT_HOST =
  process.env.REACT_APP_THREEKIT_HOST || 'https://admin-fts.threekit.com';
export const THREEKIT_AUTH_TOKEN =
  process.env.REACT_APP_AUTH_TOKEN || 'df0a7f01-5876-438c-82aa-83dabbb32067';

export const THREEKIT_ORG_ID =
  process.env.THREEKIT_ORG_ID || '003188b3-3a29-439f-949c-9e445936fdb0';
export const THREEKIT_ASSET_ID =
  process.env.THREEKIT_ASSET_ID || '5a9901e8-9038-4dcd-867a-9156f72bd886';

export const cabsWithAccents = ['Q14', 'Q17', 'Q28', 'Q36', 'Q48'];
