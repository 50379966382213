import React, { useContext } from 'react';
import { TemplateContext } from '../../../../context';
import {
  SecondarySectionWrapper,
  SectionTitle as Title,
  SectionContent as Content,
} from './mobileConfigurator.styles';
import InputComponents from '../../InputComponents';
import icnImgMissing from '../../../assets/no-image.webp';
import Notes from '../Notes';

const SecondarySection = () => {
  const { configuration, activeAddress } = useContext(TemplateContext);

  if (!configuration || activeAddress.length < 1)
    return <SecondarySectionWrapper />;

  const section = configuration[activeAddress[0]];

  return (
    <SecondarySectionWrapper expand={activeAddress.length}>
      <Content>
        <Title>{section.label}</Title>
        {section.inputs &&
          section.inputs.map((input, i) => (
            <InputComponents idx={i} key={i} {...input} />
          ))}
        {section && section.visualized === false && <Notes />}
      </Content>
    </SecondarySectionWrapper>
  );
};

export default SecondarySection;
