import React, { useState, useContext } from 'react';
import { TemplateContext } from '../../../../context';
import { Toggle } from './mobileConfigurator.styles';
import PrimarySection from './PrimarySection';
import SecondarySection from './SecondarySection';
import TertiarySection from './TertiarySection';
import { AppstoreOutlined, CloseOutlined } from '@ant-design/icons';

const MobileConfigurator = ({ show }) => {
  const { configuration, setAddress } = useContext(TemplateContext);
  const [showElement, setShowElement] = useState(true);
  const [showConfigurator, setShowConfigurator] = useState(false);

  show !== showElement && show
    ? setShowElement(show)
    : setTimeout(() => setShowElement(show), 0.4 * 1000);

  const handleClickToggle = () => {
    setShowConfigurator(!showConfigurator);
    setAddress([]);
  };

  if (!configuration) return null;

  return (
    <>
      <Toggle
        onClick={handleClickToggle}
        expand={!!showConfigurator}
        visible={!!showElement}
        show={!!showConfigurator}
      >
        <div>
          <div>
            <AppstoreOutlined style={{ fontSize: '20px' }} type="appstore" />
          </div>
          <div>
            <CloseOutlined type="close" />
          </div>
        </div>
        <div />
      </Toggle>
      <PrimarySection show={showConfigurator} />
      <SecondarySection />
      {/* <SecondarySection
        show={showConfigurator}
        address={activeAddress}
        selected={activeAddress[1]}
        onClickBackground={() => setShowConfigurator('secondary')}
        onSelect={handleSelectSecondary}
        configurator={
          activeAddress[0] != null
            ? configuration[activeAddress[0]].sections
            : null
        }
      /> */}
      {/* <TertiarySection
        show={showConfigurator}
        address={activeAddress}
        configurator={
          activeAddress[0] != null &&
          activeAddress[1] != null &&
          !!configuration[activeAddress[0]].sections.length
            ? configuration[activeAddress[0]].sections[activeAddress[1]]
            : null
        }
      /> */}
    </>
  );
};

export default MobileConfigurator;
