import cabM6 from './images/cab-m6.jpg';
import cabM10 from './images/cab-m10.jpg';
import cabM14 from './images/cab-m14.jpg';
import cabQ7 from './images/cab-q7.jpg';
import cabQ12 from './images/cab-q12.jpg';
import cabQ14 from './images/cab-q14.jpg';
import cabQ17 from './images/cab-q17.jpg';
import cabQ28 from './images/cab-q28.jpg';
import cabQ36 from './images/cab-q36.jpg';
import cabQ48 from './images/cab-q48.jpg';

import ceilingDownlight from './images/ceiling-down.jpg';
import ceilingMoulding from './images/ceiling-moulding.jpg';
import ceilingTFrame from './images/ceiling-tframe.jpg';

import handrailFlat from './images/handrail-flat.jpg';
import handrailRound from './images/handrail-round.jpg';

import woodLight from './images/wood-light.png';
import woodMedium from './images/wood-medium.png';
import woodDark from './images/wood-dark.png';

import aluminium from './images/material-aluminium.png';
import acajouMahogany from './images/material-acajou-mahogany.png';
import blackWalnut from './images/material-black-walnut.png';
import burntStrand from './images/material-burnt-strand.png';
import cinnamonCherry from './images/material-cinnamon-cherry.png';
import cocoaMaple from './images/material-coco-maple.png';

import black from './images/material-black.png';
import brass from './images/material-brass.png';
import stainlessSteel from './images/material-stainless-steel.png';

export default {
  cabM6,
  cabM10,
  cabM14,
  cabQ7,
  cabQ12,
  cabQ14,
  cabQ17,
  cabQ28,
  cabQ36,
  cabQ48,
  ceilingDownlight,
  ceilingMoulding,
  ceilingTFrame,
  handrailFlat,
  handrailRound,
  woodLight,
  woodMedium,
  woodDark,
  aluminium,
  acajouMahogany,
  blackWalnut,
  burntStrand,
  cinnamonCherry,
  cocoMaple: cocoaMaple,
  cocoaMaple,
  black,
  brass,
  stainlessSteel,
};
