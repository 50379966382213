import data from '../template-data.json';
import { cabsWithAccents } from './constants';
import { pricing } from '../data';

const { threekitConfig } = data

const isUuid = (str) => {
  const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidv4Regex.test(str);
}

export const getColor = (theme, category, darkness = 0) => {
  return theme.colors[`${theme[category]}${darkness}`];
};

const prepInput = (input, attributes) => {
  if (!attributes) return input;
  if (input.data.source !== 'platform') return input;

  const attributeName = Array.isArray(input.attribute)
    ? input.attribute[0]
    : input.attribute;

  if (!attributes[attributeName]) return;

  const preppedInput = { ...input };
  input.data.options = attributes[attributeName].values.map(asset => {
    const preppedValue = {
      ...asset,
      value: asset.id,
      label: asset.metadata._label || asset.name,
      img:
        asset.metadata._imgAssetName ||
        asset.metadata._imgAssetName ||
        undefined,
    };

    delete preppedValue.assetId;
    delete preppedValue.id;

    return preppedValue;
  });

  return preppedInput;
};

export const prepConfiguration = (configuration, attributes) => {
  if (!configuration) return null;

  if (Array.isArray(configuration))
    return configuration.map(el => prepConfiguration(el, attributes));

  if (!configuration.sections || configuration.sections.length === 0) {
    return Object.assign(
      {},
      configuration,
      { selected: null },
      configuration.inputs?.length
        ? {
            inputs: configuration.inputs.map(input =>
              prepInput(input, attributes)
            ),
          }
        : undefined
    );
  }

  return {
    ...configuration,
    selected: null,
    sections: configuration.sections.map(el =>
      prepConfiguration(el, attributes)
    ),
  };
};

export const getNestedSection = (configuration, address) => {
  if (address.length > 1) {
    const idx = address.shift();
    if (Array.isArray(configuration)) {
      return getNestedSection(configuration[idx], address);
    } else return getNestedSection(configuration.sections[idx], address);
  } else {
    if (Array.isArray(configuration)) {
      return configuration[address[0]];
    } else return configuration.sections[address[0]];
  }
};

export const updateNestedSection = (configuration, address, updateObj) => {
  if (!configuration || !address || address.length < 0 || !updateObj)
    return null;
  if (address.length > 1) {
    const idx = address.shift();
    if (Array.isArray(configuration)) {
      updateNestedSection(configuration[idx], address, updateObj);
    } else updateNestedSection(configuration.sections[idx], address, updateObj);
  } else {
    if (Array.isArray(configuration)) {
      configuration[address[0]] = {
        ...configuration[address[0]],
        ...updateObj,
      };
    } else
      configuration.sections[address[0]] = {
        ...configuration.sections[address[0]],
        ...updateObj,
      };
  }
};

export const setNestedInput = (configuration, address, idx, value) => {
  const setNestedInputInternal = (configuration, address, idx, value) => {
    if (address.length > 0) {
      const sectionIdx = address.shift();
      if (Array.isArray(configuration)) {
        setNestedInputInternal(configuration[sectionIdx], address, idx, value);
      } else
        setNestedInputInternal(
          configuration.sections[sectionIdx],
          address,
          idx,
          value
        );
    } else {
      if (!configuration.inputs || configuration.inputs.length < 1) return null;
      setInputValue(configuration.inputs[idx], value);
    }
  };

  const setInputValue = (input, value) => {
    switch (input.input) {
      case 'RadioButtons':
      case 'radioButtons':
      case 'RadioButtonsImages':
      case 'radioButtonsImage':
      case 'RadioButtonsList':
      case 'radioButtonsList':
        input.value = value;
        if (!window.configurator) break;
        const config = Array.isArray(input.attribute)
          ? input.attribute.reduce((output, attr) => {
              output[attr] = isUuid(value) ? { assetId: value } : value;
              return output;
            }, {})
          : { [input.attribute]: isUuid(value) ? { assetId: value } : value };

        window.configurator.setConfiguration(config);

        //  Hack for ceiling dependency
        //  Should be removed once the ui state is being tracked
        //  from the the threekit api's configuration state and
        //  the data source for assets is updated to use the
        //  platform
        if (
          Array.isArray(input.attribute) &&
          input.attribute.includes('Cab Primary')
        ) {
          const options = input.data.sections.reduce((output, section) => {
            section.options.forEach(opt => {
              output[opt.value] = opt.label;
            });
            return output;
          }, {});
          if (['M6', 'M10', 'M14'].includes(options[value])) {
            const ceilingSection = getNestedSection(configuration, [4]);
            if (
              !['ba6e1bb7-b0ba-49b7-bb8b-598d3cfb904f'].includes(
                ceilingSection.inputs[0].value
              )
            )
              setNestedInputInternal(
                configuration,
                [4],
                0,
                'ba6e1bb7-b0ba-49b7-bb8b-598d3cfb904f'
              );
          } else if (
            ['Q7', 'Q12', 'Q14', 'Q17', 'Q28', 'Q36', 'Q48'].includes(
              options[value]
            )
          ) {
            const ceilingSection = getNestedSection(configuration, [4]);
            if (
              ![
                '1433d223-dc75-475f-93ec-8672d859b028',
                '56b40726-c03b-4004-903c-10d6cc3e1204',
              ].includes(ceilingSection.inputs[0].value)
            )
              setNestedInputInternal(
                configuration,
                [4],
                0,
                '56b40726-c03b-4004-903c-10d6cc3e1204'
              );
          }
        }
        break;
      case 'Slider':
      case 'slider':
        input.value = value;
        if (!window.configurator) break;
        window.configurator.setConfiguration({ [input.attribute]: value });
        break;
      default:
        break;
    }
  };

  setNestedInputInternal(configuration, address, idx, value);
};

export const getDefaultConfiguration = configuration => {
  let config = {};

  const getConfigFromInput = input => {
    if (!input || !input.value) return;
    switch (input.input) {
      case 'RadioButtons':
      case 'radioButtons':
      case 'RadioButtonsImages':
      case 'radioButtonsImage':
      case 'RadioButtonsList':
      case 'radioButtonsList':
        const inputConfig = Array.isArray(input.attribute)
          ? input.attribute.reduce((output, attr) => {
              output[attr] = isUuid(input.value)
                ? { assetId: input.value }
                : input.value;
              return output;
            }, {})
          : {
              [input.attribute]: isUuid(input.value)
                ? { assetId: input.value }
                : input.value,
            };
        config = { ...config, ...inputConfig };
        break;
      case 'Slider':
      case 'slider':
        break;
      default:
        break;
    }
  };

  const getConfigFromSection = section => {
    if (!section) return;

    if (section.inputs && section.inputs.length)
      section.inputs.forEach(input => getConfigFromInput(input));

    if (section.sections && section.sections.length) {
      section.sections.forEach(section => getConfigFromSection(section));
    }
  };

  if (!configuration) return config;

  if (Array.isArray(configuration))
    configuration.forEach(section => getConfigFromSection(section));
  else getConfigFromSection(configuration);

  return config;
};

const getNestedInputs = (section, levels = 0, currentAddress = []) => {
  let inputsSet = new Set();

  if (Array.isArray(section)) {
    for (let i = 0; i < section.length; i++) {
      const updated = getNestedInputs(section[i], 1, [i]);
      inputsSet = new Set([...Array.from(inputsSet), ...updated]);
    }
    return Array.from(inputsSet);
  }

  if (section.inputs && section.inputs.length) {
    section.inputs.forEach(input => inputsSet.add(input));
  }

  if (!section.sections || section.sections.length === 0)
    return Array.from(inputsSet);

  // let newLevel = levels;

  for (let i = 0; i < section.sections.length; i++) {
    const updated = getNestedInputs(section.sections[i], levels + 1, [
      ...currentAddress,
      i,
    ]);
    // if (updated.levels > newLevel) newLevel = updated.levels;
    if (updated.inputs && updated.inputs.length)
      inputsSet = new Set([...Array.from(inputsSet), ...updated.inputs]);
  }

  return Array.from(inputsSet);
};

export const getInputsState = section =>
  getNestedInputs(section).reduce((output, input) => {
    if (isUuid(input.value) && input.input === 'radioButtons') {
      switch (input.data.type) {
        case 'multi':
        case 'multi-images':
          output[input.label] = input.data.sections
            .reduce((output, section) => {
              output = [...output, ...section.options];
              return output;
            }, [])
            .find(el => el.value === input.value).label;
          break;
        default:
          output[input.label] = input.data.options.find(
            el => el.value === input.value
          ).label;
      }
    } else {
      output[input.label] = input.value || null;
    }
    return output;
  }, {});

export const fetchItems = (...tags) => {
  let url = `${threekitConfig.environment}/api/assets?bearer_token=${threekitConfig.authToken}&orgId=${threekitConfig.orgId}&type=item&all=true`;
  url = tags.reduce((acc, tag) => `${acc}&tags=${tag}`, url);

  return fetch(url, {
    method: 'get',
  })
    .then(res => res.json())
    .catch(e => console.error(e));
};

export const createAssetMap = () =>
  fetchItems('app').then(({ assets }) => {
    return assets.reduce((acc, { id, name, tags }) => {
      tags.forEach(tag => {
        if (tag === 'app') return;
        if (!acc[tag]) acc[tag] = [];
        acc[tag].push({ id, name });
      });
      return acc;
    }, {});
  });

export const getThumbnailBlob = async (height = 600, width = 600) => {
  const size = { width, height };
  // const THUMBNAIL_CAMERA_NAME = 'Thumbnail Camera';

  const blob = await window.api.player.snapshot({ size });
  return blob;
};

const getCabPrice = (cabStyle, sizeInInches) => {
  if (!cabStyle || !sizeInInches) return null;
  const cab = pricing.cabs[cabStyle];
  const size = sizeInInches / 144;
  if (!cab) return null;
  let price = cab.basePrice;

  if (size > cab.baseSize) price += (size - cab.baseSize) * cab.perUnit;

  return price;
};

const getDoorPricing = (type, material, style) => {
  if (!type || !material || !style) return null;

  return pricing.doors[type.toLowerCase()][material.toLowerCase()][
    style.toLowerCase()
  ];
};

const getAddonPricing = (addon, sizeInInches) => {
  if (!addon || !sizeInInches) return null;
  const size = sizeInInches / 144;
  const addonPricing =
    pricing.addons.optionals[addon] || pricing.addons.defaults[addon];
  if (!addonPricing) return null;
  const { pricingBreakpoints } = pricing.addons;

  let price;
  for (let i = 0; !price && i < pricingBreakpoints.length; i++) {
    if (i === 0 && size < pricingBreakpoints[i]) {
      price = addonPricing[i];
      break;
    }
    if (i === pricingBreakpoints.length - 1) {
      if (size > pricingBreakpoints[i]) price = addonPricing[i + 1];
      break;
    }

    if (pricingBreakpoints[i] >= size && size < pricingBreakpoints[i + 1])
      price = addonPricing[i + 1];
  }
  return price;
};

export const prepConfigurationForCart = data => {
  //  Cab
  const { Height, Width, Depth, Cab, Ceiling } = data;
  const primaryColor = data['Primary Color'];
  const accentColor = data['Accent Color'];
  const woodFinish = data['Wood Finish'];
  const baseColor = data['Base Color'];
  const revealMaterial = data['Reveal Material'];
  const handrailShape = data['Handrail Shape'];
  const handrailMaterial = data['Handrail Material'];
  //  Addons
  // Doors
  const hoistwayDoorMaterial = data['Hoistway Door Material'];
  const hoistwayDoorStyle = data['Hoistway Door Style'];
  const carDoorMaterial = data['Car Door Material'];
  const carDoorStyle = data['Car Door Style'];
  // Rails
  const bumperRails = data['Bumper Rails'];
  const extraRails = data['Extra Rails'];

  const isWoodSeries = Cab[0].toLowerCase() === 'm';
  const hasAccentColor = cabsWithAccents.includes(Cab);
  const sizeInInches = parseInt(Width) * parseInt(Depth);

  const estimates = [];

  estimates.push({
    value: [
      `Cab: ${Cab}`,
      `Height: ${Height}", Width: ${Width}", Depth: ${Depth}"`,
      `${
        isWoodSeries
          ? `Wood Finish: ${woodFinish}`
          : hasAccentColor
          ? `Primary Color: ${primaryColor}, Accent Color: ${accentColor}`
          : `Primary Color: ${primaryColor}`
      }, Reveal Material: ${revealMaterial}, Base Color: ${baseColor}.`,
      `Handrail: ${handrailShape}, Handrail Material: ${handrailMaterial}`,
      `Ceiling: ${Ceiling}`,
    ],
    price: getCabPrice(Cab, sizeInInches),
  });

  //  Handle Hoistway door
  if (hoistwayDoorStyle && hoistwayDoorStyle.toLowerCase() !== 'none') {
    estimates.push({
      value: [
        `Hoistway Door Style: ${hoistwayDoorStyle}, Material: ${hoistwayDoorMaterial}`,
      ],
      price: getDoorPricing(
        'hoistway',
        hoistwayDoorMaterial,
        hoistwayDoorStyle
      ),
    });
  }

  //  Handle Car door
  if (carDoorStyle && carDoorStyle.toLowerCase() !== 'none') {
    estimates.push({
      value: [`Car Door Style: ${carDoorStyle}, Material: ${carDoorMaterial}`],
      price: getDoorPricing('car', carDoorMaterial, carDoorStyle),
    });
  }

  //  Bumper Rails
  if (bumperRails && bumperRails.toLowerCase() !== 'none') {
    const bumperRailType = `Bumper Rails ${bumperRails}`;
    estimates.push({
      value: bumperRailType,
      price: getAddonPricing(bumperRailType, sizeInInches),
    });
  }

  //  Extra Rails
  if (extraRails && parseInt(extraRails)) {
    estimates.push({
      value: 'Extra Rails',
      price: getAddonPricing('Extra Rails', sizeInInches),
    });
  }

  //  Adds pricing defaults
  Object.keys(pricing.addons.defaults).forEach(addon => {
    estimates.push({
      value: addon,
      price: getAddonPricing(addon, sizeInInches),
    });
  });

  return estimates;
};
