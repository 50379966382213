import React from "react";
import { Wrapper } from "./montevideo.styles";

import Player from "./Components/Player";
import Configurator from "./Components/Configurator";
import AddToCart from "./Components/AddToCart";

const Montevideo = () => {
  return (
    <>
      {/* <Background>
        <div />
        <div />
      </Background> */}
      <Wrapper>
        <Player />
        <Configurator>
          <AddToCart />
        </Configurator>
      </Wrapper>
    </>
  );
};

export const metadata = {
  name: "Montevideo",
  layers: 2,
};

export default Montevideo;
