import React, { useContext } from "react";
import { TemplateContext } from "../../../context";
import { Wrapper, Price, ButtonWrapper } from "./addToCart.styles";
// import { Icon } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

export const AddToCart = ({ show, visibile, handleClick }) => {
  const { general } = useContext(TemplateContext);
  const { showBom } = general;

  if (!showBom) return null;

  return (
    <Wrapper visibile={visibile} show={show}>
      <div>
        <Price>
          <div>Total</div>
          <div>$149</div>
        </Price>
      </div>
      <div>
        <ButtonWrapper onClick={handleClick}>
          <button onClick={handleClick}>
            <ShoppingCartOutlined />
          </button>
        </ButtonWrapper>
      </div>
    </Wrapper>
  );
};

export default AddToCart;
