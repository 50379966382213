import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;

  display: flex;
  flex-direction: row;

  [data-tooltip]:after {
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    pointer-events: none;

    transition: all 0.15s ease;
    padding: 10px;
    color: black;
    border-radius: 10px;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.3);
  }

  [data-tooltip]:hover:after {
    opacity: 1;
  }
`;

export const Button = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #bbbbbb;
  background: rgba(255, 255, 255, 0.3);
  margin-right: 10px;

  & > div {
    height: max-content;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #bbbbbb;
    font-size: 18px;
  }
`;
