import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 25px;
`;
