import React from 'react';
import { Wrapper } from './errorMessage.style';

const ErrorMessage = ({ message }) => {
  return (
    <Wrapper>
      <div>{message}</div>
    </Wrapper>
  );
};
export default ErrorMessage;
