import React, { useState, useContext, useEffect } from 'react';
import { Wrapper, Button } from './widgets.styles';
import { TemplateContext } from '../../../context';
import { DOOR_ANIMATION } from '../../../constants';
import {
  CameraOutlined,
  ImportOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

export const Widgets = () => {
  const { doors, setShowModal } = useContext(TemplateContext);
  const [interiorView, setInteriorView] = useState(false);
  const [doorState, setDoorState] = useState(1);
  const [progress, setProgress] = useState(0);
  const [animation, setAnimation] = useState(null);

  /* Amimation constants and functions */

  // Length of anmation in ms
  // const ANIMATION_TIME = 3000;
  // // How many frames pe animation
  // const FRAMES = 150;
  // // How far the door will go
  // const DISTANCE = 52.5;
  // Non linear movement function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2;
  }
  // wrapper on set translation api
  function setTranslation(id, initialTranslation, deltaX) {
    window.api.scene.set(
      {
        id,
        plug: 'Transform',
        property: 'translation',
      },
      Object.assign(
        { ...initialTranslation },
        { x: initialTranslation.x + deltaX }
      )
    );
  }

  useEffect(() => {
    if (progress < 0) {
      stopAnimation();
      setProgress(0);
    } else if (progress > DOOR_ANIMATION.frames) {
      stopAnimation();
      setProgress(DOOR_ANIMATION.frames);
    } else {
      const progressActual = easeInOutCubic(progress / DOOR_ANIMATION.frames);
      for (const door in doors) {
        const doorObj = doors[door];
        for (const doorId of doorObj.ids) {
          setTranslation(
            doorId,
            doorObj.translation,
            doorObj.orientation * DOOR_ANIMATION.distance * progressActual
          );
        }
      }
    }
  }, [progress, animation]);

  const updateProgress = inc => {
    setProgress(progress => progress + inc);
  };
  const animateDoor = inc => {
    stopAnimation();
    setAnimation(
      setInterval(() => {
        updateProgress(inc);
      }, DOOR_ANIMATION.duration / DOOR_ANIMATION.frames)
    );
  };
  function stopAnimation() {
    if (animation) {
      clearInterval(animation);
      setAnimation(null);
    }
  }

  /* ********************************** */

  const handleClickCamera = () => {
    window.configurator.setConfiguration({
      Camera: !interiorView ? 'Interior' : 'Exterior',
    });
    setInteriorView(!interiorView);
  };

  const handleClickAnimateDoor = () => {
    animateDoor(doorState);
    setDoorState(-doorState);
  };

  return (
    <Wrapper>
      <Button onClick={handleClickCamera}>
        <div>
          <CameraOutlined />
        </div>
      </Button>
      <Button onClick={handleClickAnimateDoor} style={{ margin: '0 15px' }}>
        <div>
          <ImportOutlined />
        </div>
      </Button>
      <Button type="primary" onClick={() => setShowModal(true)}>
        <div>
          <ShoppingCartOutlined />
        </div>
      </Button>
    </Wrapper>
  );
};

export default Widgets;
