import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../constants';
import { width } from '../Configurator/DesktopConfigurator/desktopConfigurator.styles';

export const Wrapper = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    height: ${window.innerHeight}px;
  }

  @media ${MEDIA_QUERY.tabletP} {
    height: 100vh;
  }

  #player-root {
    /* height: 1000px; */
    height: 100%;

    &
      > div:nth-child(1)
      > div:nth-child(1)
      > div:nth-child(1)
      > div:nth-of-type(3) {
      display: none;
    }

    & * * * a {
      display: none !important;
    }

    @media ${MEDIA_QUERY.mobile} {
      width: 100vw;
    }

    @media ${MEDIA_QUERY.tabletP} {
      max-width: 100%;
      max-width: calc(100vw - (${width.primary} + ${width.secondary}));
    }
  }
`;
