import React from 'react';
import { Wrapper } from './santiago.styles';

import Configurator from './Components/Configurator';
import Player from './Components/Player';
import Title from './Components/Title';
import AddToCart from './Components/AddToCart';
import Widgets from './Components/Widgets';

const App = () => {
  return (
    <Wrapper>
      <Configurator />
      <Player>
        <Widgets />
        <Title />
        <AddToCart />
      </Player>
    </Wrapper>
  );
};

export const metadata = {
  name: 'Santiago',
  layers: 2,
  inputs: ['radioButtons', 'slider'],
  features: ['bom'],
};

export default App;
