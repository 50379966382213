import styled from "styled-components";
import { MEDIA_QUERY } from "../../../constants";

export const Wrapper = styled.div`
  height: max-content;
  width: max-content;

  position: absolute;

  @media ${MEDIA_QUERY.tabletP} {
    transform: translateY(-100%);
    padding-bottom: 20px;
    padding-left: 20px;
  }

  & > div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  & > div:nth-child(1) {
    font-size: 20px;
    letter-spacing: 1px;
  }
`;
