export const reds = {
  red0: '#ffebee',
  red1: '#ffcdd2',
  red2: '#ef9a9a',
  red3: '#e57373',
  red4: '#ef5350',
  red5: '#f44336',
  red6: '#e53935',
  red7: '#d32f2f',
  red8: '#c62828',
  red9: '#b71c1c',
};

export const pinks = {
  pink0: '#FCE4EC',
  pink1: '#F8BBD0',
  pink2: '#F48FB1',
  pink3: '#F06292',
  pink4: '#EC407A',
  pink5: '#E91E63',
  pink6: '#D81B60',
  pink7: '#C2185B',
  pink8: '#AD1457',
  pink9: '#880E4F',
};

export const purples = {
  purple0: '#F3E5F5',
  purple1: '#E1BEE7',
  purple2: '#CE93D8',
  purple3: '#BA68C8',
  purple4: '#AB47BC',
  purple5: '#9C27B0',
  purple6: '#8E24AA',
  purple7: '#7B1FA2',
  purple8: '#6A1B9A',
  purple9: '#4A148C',
};

export const deepPurples = {
  deepPurple0: '#EDE7F6',
  deepPurple1: '#D1C4E9',
  deepPurple2: '#B39DDB',
  deepPurple3: '#9575CD',
  deepPurple4: '#7E57C2',
  deepPurple5: '#673AB7',
  deepPurple6: '#5E35B1',
  deepPurple7: '#512DA8',
  deepPurple8: '#4527A0',
  deepPurple9: '#311B92',
};

export const indigos = {
  indigo0: '#E8EAF6',
  indigo1: '#C5CAE9',
  indigo2: '#9FA8DA',
  indigo3: '#7986CB',
  indigo4: '#5C6BC0',
  indigo5: '#3F51B5',
  indigo6: '#3949AB',
  indigo7: '#303F9F',
  indigo8: '#283593',
  indigo9: '#1A237E',
};

export const blues = {
  blue0: '#E3F2FD',
  blue1: '#BBDEFB',
  blue2: '#90CAF9',
  blue3: '#64B5F6',
  blue4: '#42A5F5',
  blue5: '#2196F3',
  blue6: '#1E88E5',
  blue7: '#1976D2',
  blue8: '#1565C0',
  blue9: '#0D47A1',
};

export const lightBlues = {
  lightBlue0: '#E1F5FE',
  lightBlue1: '#B3E5FC',
  lightBlue2: '#81D4FA',
  lightBlue3: '#4FC3F7',
  lightBlue4: '#29B6F6',
  lightBlue5: '#03A9F4',
  lightBlue6: '#039BE5',
  lightBlue7: '#0288D1',
  lightBlue8: '#0277BD',
  lightBlue9: '#01579B',
};

export const cyans = {
  cyan0: '#E0F7FA',
  cyan1: '#B2EBF2',
  cyan2: '#80DEEA',
  cyan3: '#4DD0E1',
  cyan4: '#26C6DA',
  cyan5: '#00BCD4',
  cyan6: '#00ACC1',
  cyan7: '#0097A7',
  cyan8: '#00838F',
  cyan9: '#006064',
};

export const teals = {
  teal0: '#E0F2F1',
  teal1: '#B2DFDB',
  teal2: '#80CBC4',
  teal3: '#4DB6AC',
  teal4: '#26A69A',
  teal5: '#009688',
  teal6: '#00897B',
  teal7: '#00796B',
  teal8: '#00695C',
  teal9: '#004D40',
};

export const greens = {
  green0: '#E8F5E9',
  green1: '#C8E6C9',
  green2: '#A5D6A7',
  green3: '#81C784',
  green4: '#66BB6A',
  green5: '#4CAF50',
  green6: '#43A047',
  green7: '#388E3C',
  green8: '#2E7D32',
  green9: '#1B5E20',
};

export const lightGreens = {
  lightGreen0: '#F1F8E9',
  lightGreen1: '#DCEDC8',
  lightGreen2: '#C5E1A5',
  lightGreen3: '#AED581',
  lightGreen4: '#9CCC65',
  lightGreen5: '#8BC34A',
  lightGreen6: '#7CB342',
  lightGreen7: '#689F38',
  lightGreen8: '#558B2F',
  lightGreen9: '#33691E',
};

export const limes = {
  lime0: '#F9FBE7',
  lime1: '#F0F4C3',
  lime2: '#E6EE9C',
  lime3: '#DCE775',
  lime4: '#D4E157',
  lime5: '#CDDC39',
  lime6: '#C0CA33',
  lime7: '#AFB42B',
  lime8: '#9E9D24',
  lime9: '#827717',
};

export const yellows = {
  yellow0: '#FFFDE7',
  yellow1: '#FFF9C4',
  yellow2: '#FFF59D',
  yellow3: '#FFF176',
  yellow4: '#FFEE58',
  yellow5: '#FFEB3B',
  yellow6: '#FDD835',
  yellow7: '#FBC02D',
  yellow8: '#F9A825',
  yellow9: '#F57F17',
};

export const ambers = {
  amber0: '#FFF8E1',
  amber1: '#FFECB3',
  amber2: '#FFE082',
  amber3: '#FFD54F',
  amber4: '#FFCA28',
  amber5: '#FFC107',
  amber6: '#FFB300',
  amber7: '#FFA000',
  amber8: '#FF8F00',
  amber9: '#FF6F00',
};

export const oranges = {
  orange0: '#FFF3E0',
  orange1: '#FFE0B2',
  orange2: '#FFCC80',
  orange3: '#FFB74D',
  orange4: '#FFA726',
  orange5: '#FF9800',
  orange6: '#FB8C00',
  orange7: '#F57C00',
  orange8: '#EF6C00',
  orange9: '#E65100',
};

export const deepOranges = {
  deepOrange0: '#FBE9E7',
  deepOrange1: '#FFCCBC',
  deepOrange2: '#FFAB91',
  deepOrange3: '#FF8A65',
  deepOrange4: '#FF7043',
  deepOrange5: '#FF5722',
  deepOrange6: '#F4511E',
  deepOrange7: '#E64A19',
  deepOrange8: '#D84315',
  deepOrange9: '#BF360C',
};

export const browns = {
  brown0: '#EFEBE9',
  brown1: '#D7CCC8',
  brown2: '#BCAAA4',
  brown3: '#A1887F',
  brown4: '#8D6E63',
  brown5: '#795548',
  brown6: '#6D4C41',
  brown7: '#5D4037',
  brown8: '#4E342E',
  brown9: '#3E2723',
};

export const greys = {
  grey0: '#FAFAFA',
  grey1: '#F5F5F5',
  grey2: '#EEEEEE',
  grey3: '#E0E0E0',
  grey4: '#BDBDBD',
  grey5: '#9E9E9E',
  grey6: '#757575',
  grey7: '#616161',
  grey8: '#424242',
  grey9: '#212121',
};

export const blueGreys = {
  blueGrey0: '#ECEFF1',
  blueGrey1: '#CFD8DC',
  blueGrey2: '#B0BEC5',
  blueGrey3: '#90A4AE',
  blueGrey4: '#78909C',
  blueGrey5: '#607D8B',
  blueGrey6: '#546E7A',
  blueGrey7: '#455A64',
  blueGrey8: '#37474F',
  blueGrey9: '#263238',
};

export default {
  reds,
  pinks,
  purples,
  deepPurples,
  indigos,
  blues,
  lightBlues,
  cyans,
  teals,
  greens,
  lightGreens,
  limes,
  yellows,
  ambers,
  oranges,
  deepOranges,
  browns,
  greys,
  blueGreys,
};
