import React from 'react';

import RadioButtonsList from './RadioButtonsList';
import RadioButtonsImages from './RadioButtonsImages';

const RadioButtons = ({
  label,
  description,
  value,
  onChange: handleChange,
  data,
}) => {
  switch (data.type) {
    case 'images':
      return (
        <RadioButtonsImages
          options={data.options}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
    case 'list':
    default:
      return (
        <RadioButtonsList
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
  }
};

export default RadioButtons;
