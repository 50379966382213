import React, { useContext } from 'react';
import { TemplateContext } from '../../../context';
import { Wrapper, Price, ButtonWrapper } from './addToCart.styles';
import { ShoppingCartOutlined } from '@ant-design/icons';

import Modal from '../../../../Components/Modal';

export const AddToCart = ({ show, visibile }) => {
  const { general, setShowModal } = useContext(TemplateContext);
  const { showBom } = general;

  if (!showBom) return null;

  return (
    <>
      <Wrapper visibile={visibile} show={show}>
        <div>
          <Price>
            {/* <div>Total</div> */}
            {/* <div>$149</div> */}
          </Price>
        </div>
        <div>
          <ButtonWrapper>
            <button onClick={() => setShowModal(true)}>Get Estimate</button>
          </ButtonWrapper>
        </div>
      </Wrapper>
      <Modal />
    </>
  );
};

export default AddToCart;
