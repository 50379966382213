import styled from 'styled-components';
import { getColor } from '../../../utils';

export const Wrapper = styled.div`
  width: 400px;
  height: 100vh;
  padding: 20px 30px;
  height: 100vh;
  overflow: hidden;

  display: grid;
  grid-template-rows: max-content auto max-content;

  & > div:nth-child(2) {
    display: grid;
    grid-template-rows: max-content max-content auto;
    overflow: hidden;
  }
`;

export const PrimaryWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-right: 8px;
  }
`;

export const PrimaryItemWrapper = styled.div`
  height: 90px;
  width: 90px;
  background-color: ${props =>
    props.selected ? getColor(props.theme, 'primary', 1) : 'none'};
  border: ${props =>
    props.selected
      ? `1px solid ${getColor(props.theme, 'primary', 5)}`
      : '1px solid #bbbbbb'};
  border-radius: 3px;
  cursor: pointer;

  & > div {
    height: max-content;
    width: max-content;

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    img {
      height: 50px;
      width: 50px;
      object-fit: cover;
    }

    & > div:nth-child(2) {
      padding-top: 4px;
    }
  }
`;

export const SecondaryWrapper = styled.div`
  display: flex;
  direction: row;
  justify-content: flex-start;

  margin-bottom: 20px;
`;

export const SecondaryItemWrapper = styled.div`
  min-width: 80px;
  text-align: center;
  padding: 4px 15px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid ${props => props.theme.colors.grey7};

  background: ${props =>
    props.selected ? props.theme.colors[`${props.theme.secondary}3`] : 'none'};
`;

export const Title = styled.div`
  margin-bottom: 20px;

  & > div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  & > div:nth-child(1) {
    font-size: 20px;
    letter-spacing: 1px;
  }
`;

export const Content = styled.div`
  overflow: scroll;
`;
