import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 25px;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 40px;

  & > div:nth-child(1) {
    /* background: #00244d; */
  }
`;
