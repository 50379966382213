import React, { useContext } from 'react';
import { TemplateContext } from '../../../../context';
import {
  PrimarySectionWrapper,
  PrimaryItem,
} from './mobileConfigurator.styles';
import icnImgMissing from '../../../assets/no-image.webp';

const PrimarySection = ({ show }) => {
  const { configuration, activeAddress, setAddress } = useContext(
    TemplateContext
  );

  return (
    <PrimarySectionWrapper
      expand={show && activeAddress.length}
      onClick={() => setAddress([])}
    >
      {configuration.map((section, i) => (
        <PrimaryItem
          key={i}
          onClick={e => {
            setAddress([i]);
            e.stopPropagation();
          }}
          selected={activeAddress.length > 0 && activeAddress[0] === i}
        >
          <div>
            <div>
              <div>
                {section.icon ? (
                  <img src={icnImgMissing} alt="missing" />
                ) : (
                  section.label.charAt(0)
                )}
              </div>
            </div>
          </div>
          <div>
            <div>{section.label}</div>
          </div>
        </PrimaryItem>
      ))}
    </PrimarySectionWrapper>
  );
};

export default PrimarySection;
