import React, { useEffect, useContext, useState } from 'react';
import {
  Wrapper,
  PrimaryWrapper,
  ItemWrapper,
  PrimaryItem,
  SecondaryItems,
  SecondaryItem,
  SecondaryWrapper,
  MobileSecondaryWrapper,
  TitleWrapper,
  Content,
  MobileSecondaryItem,
} from './configurator.styles';
import { TemplateContext } from '../../../context';
import icnImgMissing from '../../assets/no-image.webp';

import InputComponents from '../InputComponents';
import Notes from './Notes';

const Item = ({
  label,
  id,
  selected,
  onClick: handleClick,
  onClickSecondary,
  sections,
}) => {
  const { activeAddress } = useContext(TemplateContext);

  const handleClickSecondary = i => onClickSecondary(i);

  return (
    <ItemWrapper selected={selected}>
      <PrimaryItem onClick={handleClick} selected={selected}>
        <div>
          <div>
            <div>{/* <img src={icnImgMissing} alt={label} /> */}</div>
            <div>{label}</div>
          </div>
        </div>
      </PrimaryItem>
      {sections && sections.length > 0 && (
        <SecondaryItems selected={selected}>
          {sections.map((section, i) => (
            <SecondaryItem
              key={i}
              onClick={() => handleClickSecondary(i)}
              selected={activeAddress.length >= 2 && activeAddress[1] === i}
            >
              <div>{section.label}</div>
            </SecondaryItem>
          ))}
        </SecondaryItems>
      )}
    </ItemWrapper>
  );
};

const Title = ({ label, onClose }) => {
  return (
    <TitleWrapper>
      <div />
      <div>{label}</div>
      <div onClick={onClose}>X</div>
    </TitleWrapper>
  );
};

const Configurator = () => {
  const {
    configuration,
    activeAddress,
    setAddress,
    getNestedObj,
    setInteriorView,
  } = useContext(TemplateContext);
  const [activeSection, setActiveSection] = useState(null);
  const { playerInitialized } = useContext(TemplateContext);

  useEffect(() => {
    (() =>
      setActiveSection(getNestedObj([...configuration], [...activeAddress])))();
  }, [activeAddress, configuration]);

  const handleClickPrimary = (address, newView) => {
    if (!playerInitialized) return;
    setAddress(address);
    setInteriorView(newView);
  };

  return (
    <Wrapper loading={!playerInitialized}>
      <PrimaryWrapper
        selected={
          activeAddress.length >= 1 &&
          configuration[activeAddress[0]].sections &&
          configuration[activeAddress[0]].sections.length > 1
        }
      >
        <div>
          {configuration.map((section, i) => (
            <Item
              key={i}
              {...section}
              selected={activeAddress.length && activeAddress[0] === i}
              onClick={() => {
                const newView =
                  activeAddress.length && activeAddress[0] === i
                    ? 'Interior'
                    : section.label;
                handleClickPrimary([i], newView);
              }}
              onClickSecondary={j => setAddress([i, j])}
            />
          ))}
        </div>
        <MobileSecondaryWrapper>
          {activeAddress.length >= 1 &&
            configuration[activeAddress[0]].sections &&
            configuration[activeAddress[0]].sections.map((el, i) => (
              <MobileSecondaryItem
                onClick={() => setAddress([activeAddress[0], i])}
                selected={activeAddress.length >= 2 && i === activeAddress[1]}
                key={i}
              >
                {el.label}
              </MobileSecondaryItem>
            ))}
        </MobileSecondaryWrapper>
      </PrimaryWrapper>
      <SecondaryWrapper
        show={
          !!activeSection &&
          activeSection.inputs &&
          activeSection.inputs.length > 0
        }
      >
        <div>
          <Title
            label={activeSection ? activeSection.label : 'No Selection'}
            onClose={() => setAddress([])}
          />
          <Content>
            {activeSection &&
              activeSection.inputs &&
              activeSection.inputs.length && (
                <div>
                  {activeSection.inputs.map((input, i) => (
                    <InputComponents key={i} idx={i} {...input} />
                  ))}
                </div>
              )}
            {activeSection && activeSection.visualized === false && <Notes />}
          </Content>
        </div>
      </SecondaryWrapper>
    </Wrapper>
  );
};

export default Configurator;
