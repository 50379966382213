import React, { useContext } from 'react';
import { Wrapper } from './title.styles';
import { TemplateContext } from '../../../context';

const Title = () => {
  const { general } = useContext(TemplateContext);
  const { title, subtitle, showTitle } = general;

  if (!showTitle || (title.length === 0 && subtitle.length === 0)) return null;

  return (
    <Wrapper>
      <div>{title}</div>
      <div>{subtitle}</div>
    </Wrapper>
  );
};

export default Title;
