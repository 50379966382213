import React, { useState, useContext, useEffect } from 'react';
import { TemplateContext } from '../../../../../context';
import { Title, Description } from '../../inputComponents.styles';
import { Wrapper, ButtonWrapper } from './radioButtonsList.styles';

const RadioButtonsList = ({ label, description, data, value, onChange }) => {
  const { getAssetsList } = useContext(TemplateContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      setOptions([]);
      if (data.source && data.source === 'platform') {
        const optionsList = await getAssetsList(data.tag);
        setOptions(optionsList);
      } else {
        setOptions(data.options);
      }
    })();
  }, [label]);

  return (
    <>
      {options.length > 0 && (
        <Wrapper>
          {label && <Title>{label}</Title>}
          {description && <Description>{description}</Description>}
          {options.map((option, i) => (
            <ButtonWrapper
              key={i}
              selected={option.value === value}
              onClick={() => onChange(option.value)}
            >
              <div>
                <div>
                  <div />
                </div>
              </div>
              <div>{option.label}</div>
            </ButtonWrapper>
          ))}
        </Wrapper>
      )}
    </>
  );
};

export default RadioButtonsList;
