import React from 'react';

import RadioButtonsList from './RadioButtonsList';
import RadioButtonsListMulti from './RadioButtonsListMulti';
import RadioButtonsImages from './RadioButtonsImages';
import RadioButtonsImagesMulti from './RadioButtonsImagesMulti';
import RadioButtonsColors from './RadioButtonsColors';

const RadioButtons = ({
  label,
  description,
  value,
  onChange: handleChange,
  data,
}) => {
  switch (data.type) {
    case 'images':
      return (
        <RadioButtonsImages
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
    case 'multi':
    case 'multi-list':
      return (
        <RadioButtonsListMulti
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
    case 'multi-images':
      return (
        <RadioButtonsImagesMulti
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
    case 'colors':
      return (
        <RadioButtonsColors
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
    case 'list':
    default:
      return (
        <RadioButtonsList
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={val => handleChange(val)}
        />
      );
  }
};

export default RadioButtons;
