import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../constants';
import { getColor } from '../../../utils';

export const Title = styled.div`
  letter-spacing: 1px;
  color: #888888;
  text-transform: uppercase;

  @media ${MEDIA_QUERY.mobile} {
    font-size: 13px;
    margin-bottom: 8px;
  }

  @media ${MEDIA_QUERY.tabletP} {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const Description = styled.div`
  margin-bottom: 12px;
`;
