import React from 'react';
import { Wrapper } from './bogota.styles.js';

import Player from './Components/Player';
import Configurator from './Components/Configurator';
import AddToCart from './Components/AddToCart';

const Bogota = () => {
  return (
    <Wrapper>
      <Player />
      <Configurator>
        <AddToCart />
      </Configurator>
    </Wrapper>
  );
};

export const metadata = {
  name: 'Bogota',
  layers: 2,
  inputs: ['radioButtons', 'slider'],
  features: ['bom'],
};

export default Bogota;
