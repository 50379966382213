import React from 'react';
import {
  Wrapper,
  ColorsWrapper,
  ColorWrapper,
  Selected,
  ColorImage,
} from './radioButtonsColors.styles';
import { Title, Description } from '../../inputComponents.styles';
import { Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import assets from '../../../../../../assets';

const Color = ({ img, color, label, selected, onClick }) => (
  <Tooltip placement="top" title={label}>
    <ColorWrapper color={!img ? color : null} onClick={onClick}>
      {img && assets[img] && (
        <ColorImage>
          <img src={assets[img]} alt="missing" />
        </ColorImage>
      )}
      {selected && (
        <Selected>
          <CheckOutlined />
        </Selected>
      )}
    </ColorWrapper>
  </Tooltip>
);

const ColorSelector = ({ label, description, data, value, onChange }) => {
  return (
    <Wrapper>
      {label && <Title>{label}</Title>}
      {description && <Description>{description}</Description>}
      <ColorsWrapper>
        {data.options.map(({ label, img, color, value: colorValue }, i) => (
          <Color
            key={i}
            img={img}
            color={color}
            label={label}
            selected={colorValue === value}
            onClick={() => {
              onChange(colorValue);
            }}
          />
        ))}
      </ColorsWrapper>
    </Wrapper>
  );
};

export default ColorSelector;
