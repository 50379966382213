import styled from "styled-components";
import { MEDIA_QUERY } from "../../../constants";
import { getColor } from "../../../utils";

const SIZE = {
  mobile: "40px",
};

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;

  display: grid;
  grid-template-columns: auto max-content;

  @media ${MEDIA_QUERY.mobile} {
    grid-gap: 10px;
  }
  @media ${MEDIA_QUERY.tabletP} {
    grid-gap: 30px;
  }
  @media ${MEDIA_QUERY.desktop} {
    grid-gap: 30px;
  }
`;

export const Price = styled.div`
  & > div:nth-child(1) {
    color: rgba(0, 0, 0, 0.4);
    @media ${MEDIA_QUERY.mobile} {
      display: none;
    }
    @media ${MEDIA_QUERY.tabletP} {
      font-size: 13px;
    }
    @media ${MEDIA_QUERY.desktop} {
      font-size: 15px;
    }
  }
  & > div:nth-child(2) {
    color: black;
    @media ${MEDIA_QUERY.mobile} {
      color: rgba(10, 10, 10, 0.6);
      font-weight: 600;
      font-size: 20px;
      position: absolute;
      top: 50%;
      transform: translateX(-100%) translateY(-50%);
    }
    @media ${MEDIA_QUERY.tabletP} {
      color: black;
      font-size: 24px;
      line-height: 20px;
    }
    @media ${MEDIA_QUERY.desktop} {
      color: black;
      font-size: 27px;
      line-height: 23px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    cursor: pointer;
    background: ${(props) => getColor(props.theme, "tertiary", 5)};
    color: white;
    border: none;
    outline: none;
    transition: all 0.2s;

    &:hover {
      background: ${(props) => getColor(props.theme, "tertiary", 2)};
    }

    @media ${MEDIA_QUERY.mobile} {
      height: ${SIZE.mobile};
      width: ${SIZE.mobile};
      border-radius: 50%;
      font-size: 18px;
      text-align: center;
      padding: 0;
    }
    @media ${MEDIA_QUERY.tabletP} {
      height: 36px;
      width: 120px;
      border-radius: 6px;
      font-size: 24px;
    }
    @media ${MEDIA_QUERY.desktop} {
      height: 40px;
      width: 150px;
      border-radius: 8px;
      font-size: 24px;
    }
  }

  position: relative;
  top: 100%;
  transform: translateY(-100%);
  i::after {
    content: " Add to cart";
  }
`;
