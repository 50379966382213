import styled from 'styled-components';

export const PrimaryItemWrapper = styled.div`
  height: max-content;
  border: 1px solid #d3d4d3;
  border-radius: 3px;
  width: 400px;

  & > div:nth-child(1) {
    height: 45px;
    padding: 0 15px;
    background: #fafafa;
    border-radius: 3px;
    cursor: pointer;

    display: grid;
    grid-template-columns: auto max-content;

    & > div {
      height: max-content;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & > div:nth-child(2) {
    max-height: ${props => (props.selected ? '330px' : 0)};
    transition: all ${props => props.theme.transitionDuration};
    overflow-y: scroll;

    & > div {
      padding: 20px 30px;
    }
  }
`;

export const Wrapper = styled.div`
  width: max-content;
  padding: 20px 30px;
  height: 100vh;

  display: grid;
  grid-template-rows: max-content auto max-content;

  & > div:nth-child(3) {
    padding-top: 25px;
  }
`;

export const Title = styled.div`
  margin-bottom: 30px;

  & > div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  & > div:nth-child(1) {
    font-size: 20px;
    letter-spacing: 1px;
  }
`;

export const SecondaryItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const SecondaryItem = styled.div`
  cursor: pointer;
  min-width: 80px;
  text-align: center;
  padding: 4px 15px;
  border-radius: 3px;
  margin-right: 8px;
  border: 1px solid ${props => props.theme.colors[`${props.theme.primary}5`]};
  color: ${props =>
    props.selected ? 'white' : props.theme.colors[`${props.theme.primary}5`]};

  :hover {
    ${props =>
      !props.selected &&
      `background: ${props.theme.colors[`${props.theme.primary}1`]}`};
  }

  background: ${props =>
    props.selected ? props.theme.colors[`${props.theme.primary}7`] : 'white'};
`;

export const ItemsWrapper = styled.div`
  padding-top: 20px;
`;
