import React, { useContext, useState, useEffect } from 'react';
import {
  Wrapper,
  Title,
  PrimaryWrapper,
  PrimaryItemWrapper,
  SecondaryWrapper,
  SecondaryItemWrapper,
  Content,
} from './configurator.styles';
import { TemplateContext } from '../../../context';
import icnImgMissing from '../../assets/no-image.webp';

import InputComponents from '../InputComponents';

const PrimaryItem = ({ label, selected, onClick: handleClick }) => {
  return (
    <PrimaryItemWrapper selected={selected} onClick={handleClick}>
      <div>
        <div>
          <img src={icnImgMissing} alt="missing" />
        </div>
        <div>{label}</div>
      </div>
    </PrimaryItemWrapper>
  );
};

const Configurator = ({ children }) => {
  const {
    general,
    configuration,
    activeAddress,
    setAddress,
    getNestedObj,
  } = useContext(TemplateContext);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    (() =>
      setActiveSection(getNestedObj([...configuration], [...activeAddress])))();
  }, [activeAddress, configuration]);

  return (
    <Wrapper>
      {(general.title.length > 0 || general.subtitle.length > 0) && (
        <Title>
          <div>{general.title}</div>
          <div>{general.subtitle}</div>
        </Title>
      )}
      <div>
        <PrimaryWrapper>
          {configuration.map((section, i) => (
            <PrimaryItem
              key={i}
              {...section}
              selected={activeAddress.length && activeAddress[0] === i}
              onClick={() => setAddress([i])}
            />
          ))}
        </PrimaryWrapper>
        <div>
          {activeAddress &&
            activeAddress.length > 0 &&
            configuration[activeAddress[0]].sections &&
            configuration[activeAddress[0]].sections.length > 0 && (
              <SecondaryWrapper>
                {configuration[activeAddress[0]].sections.map((section, i) => (
                  <SecondaryItemWrapper
                    key={i}
                    onClick={() => setAddress([activeAddress[0], i])}
                    selected={activeAddress[1] === i}
                  >
                    {section.label}
                  </SecondaryItemWrapper>
                ))}
              </SecondaryWrapper>
            )}
        </div>
        <Content>
          {activeSection &&
            activeSection.inputs &&
            activeSection.inputs.length && (
              <div>
                {activeSection.inputs.map((input, i) => (
                  <InputComponents key={i} idx={i} {...input} />
                ))}
              </div>
            )}
        </Content>
      </div>
      <div>{children}</div>
    </Wrapper>
  );
};

export default Configurator;
