import React, { useState, useContext } from "react";
import { TemplateContext } from "../../../../context";
import { Wrapper } from "./desktopConfigurator.styles";
import PrimarySecondarySection from "./PrimarySecondarySection";
import TertiarySection from "./TertiarySection";

const DesktopConfigurator = () => {
  const { configuration, activeAddress, setAddress } = useContext(
    TemplateContext
  );
  let section = null;

  if (
    configuration &&
    configuration.length &&
    activeAddress &&
    activeAddress.length
  )
    section = configuration[activeAddress[0]];

  const [expand, setExpand] = useState(false);

  const handleClickPrimary = (idx) => {
    if (idx === activeAddress[0]) return setExpand(!expand);
    setAddress([idx]);
    setExpand(true);
  };

  const handleClickSecondary = (address) => {
    setAddress(address);
    setExpand(true);
  };

  return (
    <Wrapper>
      {configuration && (
        <>
          <PrimarySecondarySection
            configurator={configuration}
            selectedPrimary={activeAddress[0]}
            selectedSecondary={activeAddress[1]}
            expand={expand}
            handleClickPrimary={handleClickPrimary}
            handleClickSecondary={handleClickSecondary}
          />
          {activeAddress &&
            configuration[activeAddress[0]] &&
            configuration[activeAddress[0]].sections[activeAddress[1]] && (
              <TertiarySection
                address={[activeAddress[0], activeAddress[1]]}
                {...configuration[activeAddress[0]].sections[activeAddress[1]]}
              />
            )}
        </>
      )}
    </Wrapper>
  );
};

export default DesktopConfigurator;
