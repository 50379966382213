import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const ColorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-top: 12px;
  padding-left: 5px;
`;

export const ColorWrapper = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  ${props => props.color && `background: ${props.color};`};
  overflow: hidden;

  position: relative;

  margin-right: 16px;
  margin-bottom: 25px;
`;

export const ColorImage = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;

  position: absolute;

  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const Selected = styled.div`
  text-align: center;
  color: white;
  height: min-content;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  font-size: 20px;
`;
