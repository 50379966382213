import React, { useState, useContext, useEffect } from 'react';
import { TemplateContext } from '../../../../../context';
import { Title, Description } from '../../inputComponents.styles';
import { Wrapper, ButtonWrapper } from './radioButtonsListMulti.styles';

const RadioButtonsList = ({ label, description, data, value, onChange }) => {
  //   const { getAssetsList } = useContext(TemplateContext);
  //   const [sections, setSections] = useState([]);

  //   useEffect(() => {
  //     (async () => {
  //       setOptions([]);
  //     //   if (data.source && data.source === 'platform') {
  //         const optionsList = await getAssetsList(data.tag);
  //         setOptions(optionsList);
  //       } else {
  //         setOptions(data.options);
  //       }
  //     })();
  //   }, [label]);

  return (
    <>
      {data.sections.map((section, i) => (
        <Wrapper key={i}>
          {section.label && <Title>{section.label}</Title>}
          {section.description && (
            <Description>{section.description}</Description>
          )}
          {section.options.map((option, j) => (
            <ButtonWrapper
              key={j}
              selected={option.value === value}
              onClick={() => onChange(option.value)}
            >
              <div>
                <div>
                  <div />
                </div>
              </div>
              <div>{option.label}</div>
            </ButtonWrapper>
          ))}
        </Wrapper>
      ))}
    </>
  );
};

export default RadioButtonsList;
