import React from 'react';
import { Slider, InputNumber } from 'antd';
import { Title, Description } from '../inputComponents.styles';
import { ContentWrapper } from './slider.styles';

const SliderInput = ({
  label,
  description,
  value,
  min,
  max,
  step,
  onChange,
}) => {
  const handleChangeSlider = value => {
    Array.isArray(value) ? onChange(value[0]) : onChange(value);
  };
  const validateValue = value =>
    value >= min && value <= max && (value - min) % step === 0;
  const handleChangeInput = value => {
    value && validateValue(value) && onChange(value);
  };
  return (
    <div>
      {label && <Title>{label}</Title>}
      {description && <Description>{description}</Description>}
      <ContentWrapper>
        <Slider
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={handleChangeSlider}
        />
        <InputNumber
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChangeInput}
          style={{ width: 70 }}
        />
      </ContentWrapper>
    </div>
  );
};

export default SliderInput;
