import React from 'react';
import {
  prepConfiguration,
  getNestedSection,
  updateNestedSection,
  setNestedInput,
  getInputsState,
  // createAssetMap,
  getDefaultConfiguration,
  getThumbnailBlob,
  prepConfigurationForCart,
} from './utils';
import { BASE_URL, THREEKIT_HOST, THREEKIT_AUTH_TOKEN } from './constants';
import axios from 'axios';

export const TemplateContext = React.createContext(null);

const getProducts = () => {
  const url = `${THREEKIT_HOST}/api/catalog/products/?bearer_token=${THREEKIT_AUTH_TOKEN}&all=true`;
  return axios.get(url);
};
class AppProvider extends React.Component {
  setAddress = address => {
    if (!address || !Array.isArray(address))
      return this.setState({ activeAddress: [] });

    if (JSON.stringify(address) === JSON.stringify(this.state.activeAddress)) {
      const updatedAddress = [...this.state.activeAddress];
      updatedAddress.pop();
      return this.setState({ activeAddress: updatedAddress });
    }

    let updated = Array.isArray(this.state.configuration)
      ? [...this.state.configuration]
      : { ...this.state.configuration };
    let location = [...address];
    const idx = location.pop();
    updateNestedSection(updated, location, { selected: idx });
    this.setState({ activeAddress: address, configuration: updated });
  };

  getAssetsList = tag => {
    if (!tag) return;
    const { orgId, authToken, environment } = this.props.threekitConfig;
    return new Promise(async (resolve, reject) => {
      const response = await axios.get(
        `${
          THREEKIT_HOST || environment || 'https://preview.threekit.com'
        }/api/assets?tags=${tag}&orgId=${orgId}&bearer_token=${authToken}&all=true`
      );
      if (response.status !== 200) reject();
      else
        resolve(
          response.data.assets.map(asset => ({
            label: asset.name,
            value: asset.id,
          }))
        );
    });
  };

  updateNestedInput = (idx, value) => {
    const updated = Array.isArray(this.state.configuration)
      ? [...this.state.configuration]
      : { ...this.state.configuration };
    setNestedInput(updated, [...this.state.activeAddress], idx, value);

    this.setState({
      configuration: updated,
      inputState: getInputsState(updated),
    });
  };

  handleAddToCart = data => {
    return new Promise(async (resolve, reject) => {
      if (!data) reject('user data is required');

      const url = `${BASE_URL}/pdf`;

      try {
        const thumbnail = await getThumbnailBlob();
        const formData = new FormData();
        formData.append('thumbnail', thumbnail);
        formData.append('userData', JSON.stringify(data));
        formData.append(
          'configuration',
          JSON.stringify(window.configurator.getConfiguration())
        );
        formData.append(
          'lineItems',
          JSON.stringify(prepConfigurationForCart(this.state.inputState))
        );
        formData.append('storeName', window.location.host);

        const response = await axios({
          method: 'post',
          url,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status !== 200) resolve(false);
        else resolve(true);
      } catch (err) {
        reject(false);
      }
    });
  };

  setInteriorView = attr => {
    const CAMERA_MAP = {
      Ceiling: 'Ceiling',
      Handrail: 'Handrail',
      Style: 'Style',
      Materials: 'Style',
    };
    const interiorView = CAMERA_MAP[attr] || 'Interior';

    if (this.state.isInteriorCamera) {
      if (this.state.interiorView === interiorView)
        window.api.player.cameraController.restoreInitialCameraState();
      else window.configurator.setConfiguration({ Camera: interiorView });
    }

    return this.setState({ interiorView });
  };

  toggleCamera = () => {
    const { Camera } = window.configurator.getConfiguration();
    const CAM_LOOP = ['Interior', 'Exterior', 'External'];
    const index = CAM_LOOP.indexOf(Camera);
    const camera =
      index <= 0 ? CAM_LOOP[1] : CAM_LOOP[(index + 1) % CAM_LOOP.length];
    const isInteriorCamera = camera === 'Interior';
    window.configurator.setConfiguration({ Camera: camera });
    return this.setState({ isInteriorCamera });
  };

  setShowModal = val =>
    this.setState({ showModal: val || !this.state.showModal });

  state = {
    playerInitialized: false,
    showModal: false,
    general: this.props.general,
    activeAddress: [],
    configuration: prepConfiguration(this.props.configuration),
    inputState: getInputsState(prepConfiguration(this.props.configuration)),
    getAssetsList: this.getAssetsList,
    setAddress: this.setAddress,
    getNestedObj: getNestedSection,
    updateNestedInput: this.updateNestedInput,
    handleAddToCart: this.handleAddToCart,
    setShowModal: this.setShowModal,
    isInteriorCamera: true,
    interiorView: 'Interior',
    setInteriorView: this.setInteriorView,
    toggleCamera: this.toggleCamera,
  };

  initalizePlayer = async () => {
    const threekitConfig = {
      authToken: THREEKIT_AUTH_TOKEN || this.props.threekitConfig.authToken,
      el: document.getElementById('player-root'),
      assetId: this.props.threekitConfig.assetId,
      showAR: this.props.threekitConfig.showAr,
      showShare: this.props.threekitConfig.showShare,
      display: this.props.threekitConfig.display,
      initialConfiguration: getDefaultConfiguration(this.props.configuration),
    };
    const environment = process.env.REACT_APP_ENV;
    if (environment !== 'development') {
      console.log('Use caches');
      threekitConfig.cache = {
        maxAge: 2592000,
        scope: 'v1.0',
      };
    } else {
      threekitConfig.publishStage = 'draft';
    }

    // const [api, response] = await Promise.all([
    //   window.threekitPlayer(threekitConfig),
    //   getProducts(),
    // ]);
    const response = await getProducts()
    const api = await window.threekitPlayer(threekitConfig)

    //  Compiles the catalog as an object indexed by their asset Ids
    const products = response.data.products.reduce(
      (output, item) =>
        Object.assign(output, {
          [item.id]: {
            id: item.id,
            name: item.name,
            metadata: item.metadata,
            tags: item.tags,
          },
        }),
      {}
    );

    //  Get the configurator attributes and hydrates them with
    //  the data from the products dataset
    const attributes = await api.getConfigurator().then(configurator =>
      configurator.getAttributes().reduce((output, attr) => {
        if (attr.type === 'Asset') {
          return Object.assign(output, {
            [attr.name]: {
              ...attr,
              values: attr.values.map(opt => ({
                ...opt,
                ...products[opt.assetId],
              })),
            },
          });
        }
        return Object.assign(output, { [attr.name]: attr });
      }, {})
    );

    api.enableApi('store');
    window.player = api.enableApi('player');
    api.tools.removeTool('pan');
    window.api = api;

    //  New Code
    window.configurator = await api.getConfigurator();

    window.configurator.setConfiguration(
      getDefaultConfiguration(this.props.configuration)
    );
    const doors = await this.getDoorInstances();
    this.setState({
      doors,
      playerInitialized: true,
      configuration: prepConfiguration(this.props.configuration, attributes),
    });
  };

  async getDoorInstances() {
    const EXTERIOR = 'Exterior_Hallway';
    const CABS = ['Main_Doors', 'Doors_Clone'];
    const DOOR_RIGHT = 'Door_Right';
    const DOOR_LEFT = 'Door_Left';
    const doors = {
      [DOOR_RIGHT]: { ids: [] },
      [DOOR_LEFT]: { ids: [] },
    };
    const assetInstanceId = await window.api.player.getAssetInstance({
      id: window.api.player.instanceId,
      plug: 'Proxy',
      property: 'asset',
    });
    const exteriorInstanceId = await window.player.getAssetInstance({
      from: assetInstanceId,
      name: EXTERIOR,
      plug: 'Null',
      property: 'asset',
    });
    for (const cab of CABS) {
      const cabInstanceId = await window.api.scene.findNode({
        from: exteriorInstanceId,
        name: cab,
      });
      for (const door in doors) {
        const doorObj = doors[door];
        const id = window.api.scene.findNode({
          from: cabInstanceId,
          name: door,
        });
        doorObj.ids.push(id);
        if (!doorObj.translation) {
          doorObj.translation = window.api.scene.get({
            id,
            plug: 'Transform',
            property: 'translation',
          });
          doorObj.orientation = doorObj.translation.x >= 0 ? -1 : 1;
        }
      }
    }
    return doors;
  }

  async componentDidMount() {
    if (
      this.props.threekitConfig.authToken.length &&
      this.props.threekitConfig.assetId.length
    )
      this.initalizePlayer();
  }

  componentDidUpdate() {
    process.env.NODE_ENV === 'development' && console.log(this.state);

    if (!this.state.playerInitialized)
      if (
        this.props.threekitConfig.authToken.length &&
        this.props.threekitConfig.assetId.length
      ) {
        this.initalizePlayer();
      }
  }

  render() {
    return (
      <TemplateContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </TemplateContext.Provider>
    );
  }
}

export default AppProvider;
