import styled from 'styled-components';
import { MEDIA_QUERY } from '../../Templates/constants';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);

  & > div {
    width: max-content;
    max-width: calc(100vw - 30px);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 40px 60px;
    background: white;
    border-radius: 5px;

    @media ${MEDIA_QUERY.mobile} {
      padding: 15px 30px;
    }

    & > div:nth-child(1) {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }

    & > div:nth-child(2) {
      margin: 25px 0;
    }

    & > div:nth-child(3) {
      text-align: right;
    }
  }
`;

export const Field = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-gap: 12px;

  margin-bottom: 12px;

  & > div:nth-child(1) {
    padding-top: 3px;
  }
`;
