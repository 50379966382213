import styled from 'styled-components';
import { getColor } from '../../../utils';
import { MEDIA_QUERY } from '../../../constants';

export const WIDTH = {
  primary: '90px',
  secondary: '320px',
};

export const HEIGHT = {
  mobileSecondary: '34px',
};

export const Wrapper = styled.div`
  z-index: 5;

  & div {
    ${props => (props.loading ? 'cursor: wait !important;' : '')}
  }

  @media ${MEDIA_QUERY.mobile} {
    height: calc(${WIDTH.primary} + ${HEIGHT.mobileSecondary});
    width: 100vw;
    overflow: hidden;
  }

  @media ${MEDIA_QUERY.tabletP} {
    width: max-content;
    display: grid;
    grid-template-columns: max-content max-content;

    overflow: visible;
  }
`;

export const PrimaryWrapper = styled.div`
  height: max-content;
  background: #4a7e9e;
  z-index: 7;

  @media ${MEDIA_QUERY.mobile} {
    position: absolute;
    height: calc(${WIDTH.primary} + ${HEIGHT.mobileSecondary});
    min-width: 100vw;
    overflow: hidden;

    margin-top: ${props => (props.selected ? '0' : '34px')};
    transition: all 0.3s;
  }

  & > div:nth-child(1) {
    @media ${MEDIA_QUERY.mobile} {
      /* position: absolute; */
      /* height: ${WIDTH.primary}; */
      height: max-content;
      min-width: 100vw;

      display: flex;
      justify-content: center;
      border-right: 1px solid white;
    }

    @media ${MEDIA_QUERY.tabletP} {
      /* width: 90px; */
      width: max-content;
      /* padding: 0 18px; */
      min-height: 100vh;
      background: #4a7e9e;
      box-shadow: inset -10px 0px 10px 0px rgba(0, 0, 0, 0.03);
    }
  }
`;

export const ItemWrapper = styled.div`
  height: max-content;
  transition: all 0.3s;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  background: ${props =>
    props.selected ? getColor(props.theme, 'primary', 0) : 'none'};
  /* background: ${props => (props.selected ? '#4A7E9E' : 'none')}; */
  margin: 5px;
  ${props =>
    props.selected &&
    `box-shadow: inset 0 0 10px 3px ${getColor(props.theme, 'primary', 9)}30;`}
  border-radius: 2px;

  overflow: hidden;
`;

export const PrimaryItem = styled.div`
  height: max-content;

  background: ${props => (props.selected ? '#5ea6d1' : 'none')};
  /* border: ${props =>
    props.selected
      ? `1px solid ${getColor(props.theme, 'primary', 5)}`
      : 'none'}; */
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    ${props =>
      !props.selected && `background:  ${getColor(props.theme, 'primary', 0)};`}
  }

  img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    margin-bottom: 3px;
  }

  & > div {
    margin: 0px auto;

    @media ${MEDIA_QUERY.mobile} {
      width: 70px;
      height: ${WIDTH.primary};
    }

    @media ${MEDIA_QUERY.tabletP} {
      height: 60px;
      /* height: ${WIDTH.primary};
      width: ${WIDTH.primary}; */
      /* border-bottom: 1px solid #bbbbbb; */
    }

    & > div {
      height: max-content;
      width: max-content;
      position: relative;
      top: 50%;
      /* left: 50%; */
      /* transform: translate(-50%, -50%); */
      transform: translateY(-50%);
      padding-left: 20px;
      padding-right: 20px;

      /* text-align: center; */
    }
  }
`;

export const MobileSecondaryWrapper = styled.div`
  height: max-content;
  padding: 3px;
  min-width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media ${MEDIA_QUERY.tabletP} {
    display: none;
  }
`;

export const MobileSecondaryItem = styled.div`
  padding: 3px 10px;
  text-align: center;
  min-width: 60px;
  margin-right: 8px;
  border-radius: 18px;

  background: ${props => (props.selected ? 'blue' : 'none')};
`;

export const SecondaryItems = styled.div`
  max-height: ${props => (props.selected ? '300px' : '0px')};
  transition: all 0.3s;
  background: #5ea6d1;

  @media ${MEDIA_QUERY.mobile} {
    display: none;
  }
`;

export const SecondaryItem = styled.div`
  padding: 15px 15px;

  display: grid;
  grid-template-columns: 12px auto;
  grid-gap: 8px;

  cursor: pointer;
  font-size: 13px;
  font-weight: ${props => (props.selected ? '600' : '400')};
`;

export const SecondaryWrapper = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    width: 100vw;
    height: calc(100vh - ${WIDTH.primary});
    background: rgba(255, 255, 255, 0.85);
    overflow: hidden;
    z-index: 4;

    position: fixed;
    top: ${props => (props.show ? '0%' : `calc(100vh - ${WIDTH.primary})`)};

    transition: all 0.3s;

    & > div {
      width: 100vw;
      height: calc(100vh - ${WIDTH.primary});
    }
  }

  @media ${MEDIA_QUERY.tabletP} {
    width: ${props => (props.show ? WIDTH.secondary : '0px')};
    background: ${props => props.theme.colors.grey0};
    overflow: hidden;

    transition: all 0.3s;

    & > div {
      width: ${WIDTH.secondary};
      height: 100vh;

      display: grid;
      grid-template-rows: max-content auto;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 50% 1fr;

  height: 70px;

  & > div:nth-child(2) {
    padding-top: 20px;
    border-bottom: 1px solid #bbbbbb;
    text-align: center;
    font-size: 20px;
  }

  & > div:nth-child(3) {
    height: max-content;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Content = styled.div`
  padding: 25px;
  overflow: scroll;
`;
