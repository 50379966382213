import React, { useState, useContext, useEffect } from 'react';
import { TemplateContext } from '../../../../../context';
import { Title, Description } from '../../inputComponents.styles';
import {
  Wrapper,
  ButtonsRow,
  ButtonWrapper,
} from './radioButtonsImages.styles';
import assets from '../../../../../../assets';
import icnImgMissing from '../../../../assets/no-image.webp';

const RadioButtonsImages = ({
  label,
  description,
  images,
  data,
  value,
  onChange,
}) => {
  const { getAssetsList, inputState } = useContext(TemplateContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      setOptions([]);
      if (data.source && data.source === 'platform') {
        const optionsList = await getAssetsList(data.tag);
        setOptions(optionsList);
      } else {
        setOptions(data.options);
      }
    })();
  }, [label]);

  return (
    <>
      {options && (
        <Wrapper>
          {label && <Title>{label}</Title>}
          {description && <Description>{description}</Description>}
          <ButtonsRow>
            {options
              .filter(option => {
                if (option.dependencies && option.dependencies.length)
                  for (let i = 0; i < option.dependencies.length; i++) {
                    if (
                      !(option.dependencies[i].label in inputState) ||
                      !option.dependencies[i].values.includes(
                        inputState[option.dependencies[i].label]
                      )
                    )
                      return false;
                  }
                return true;
              })
              .map((option, i) => (
                <ButtonWrapper
                  key={i}
                  selected={option.value === value}
                  onClick={() => onChange(option.value)}
                >
                  <div>
                    <img
                      src={
                        option.img && assets[option.img]
                          ? assets[option.img]
                          : option.imgUrl || icnImgMissing
                      }
                      alt="missing"
                    />
                  </div>
                  <div>{option.label}</div>
                </ButtonWrapper>
              ))}
          </ButtonsRow>
        </Wrapper>
      )}
    </>
  );
};

export default RadioButtonsImages;
