import styled from "styled-components";

export const Wrapper = styled.div`
  height: max-content;
  width: max-content;

  position: absolute;
  top: 100%;
  transform: translateY(-100%);

  padding-bottom: 25px;
  padding-left: 25px;

  & > div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  & > div:nth-child(1) {
    font-size: 20px;
    letter-spacing: 1px;
  }
`;
