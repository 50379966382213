import React, { useContext } from 'react';
import { Wrapper } from './title.styles';
import { TemplateContext } from '../../../context';

const Title = () => {
  const { general } = useContext(TemplateContext);
  const { showProductName, title, subtitle } = general;

  if (!showProductName) return null;

  return (
    <Wrapper>
      <div>{title}</div>
      <div>{subtitle}</div>
    </Wrapper>
  );
};

export default Title;
