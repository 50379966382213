import React, { useContext } from 'react';
import Slider from './Slider';
import RadioButtons from './RadioButtons';

import { TemplateContext } from '../../../context';

const InputComponets = ({ idx, input, label, description, data, value }) => {
  const { updateNestedInput } = useContext(TemplateContext);

  const handleUpdate = val => updateNestedInput(idx, val);

  switch (input) {
    case 'slider':
    case 'Slider':
      return (
        <Slider
          min={data.min}
          max={data.max}
          step={data.step}
          label={label}
          description={description}
          value={value}
          onChange={val => handleUpdate(val)}
        />
      );
    case 'radioButtons':
    case 'RadioButtons':
      return (
        <RadioButtons
          data={data}
          label={label}
          description={description}
          value={value}
          onChange={handleUpdate}
        />
      );
    default:
      return <div>No component for {input} was found</div>;
  }
};

export default InputComponets;
