import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../constants';
import { WIDTH, HEIGHT } from '../Configurator/configurator.styles';

export const Wrapper = styled.div`
  #player-root {
    @media ${MEDIA_QUERY.mobile} {
      height: calc(100vh - (${WIDTH.primary} + ${HEIGHT.mobileSecondary}));
      width: 100vw;
    }

    @media ${MEDIA_QUERY.tabletP} {
      height: 100vh;
      width: calc(100vw - 130px);
    }
  }
`;
