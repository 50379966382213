import React, { useContext, useEffect, useState } from "react";
import {
  Wrapper,
  PrimaryItemWrapper,
  Title,
  SecondaryItems,
  SecondaryItem,
  ItemsWrapper,
} from "./configurator.styles";
import { TemplateContext } from "../../../context";
import icnImgMissing from "../../assets/no-image.webp";
import { DownOutlined } from "@ant-design/icons";

import InputComponets from "../InputComponents";

const Configurator = ({ children }) => {
  const {
    general,
    configuration,
    activeAddress,
    setAddress,
    getNestedObj,
  } = useContext(TemplateContext);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    (() =>
      setActiveSection(getNestedObj([...configuration], [...activeAddress])))();
  }, [activeAddress, configuration]);

  return (
    <Wrapper>
      {(general.title.length > 0 || general.subtitle.length > 0) && (
        <Title>
          <div>{general.title}</div>
          <div>{general.subtitle}</div>
        </Title>
      )}
      <div>
        {configuration.map((section, i) => (
          <PrimaryItemWrapper
            key={i}
            selected={activeAddress.length >= 1 && activeAddress[0] === i}
          >
            <div onClick={() => setAddress([i])}>
              <div>{section.label}</div>
              <div>
                <DownOutlined
                  style={{
                    transition: `all 0.3s`,
                    transform:
                      activeAddress.length >= 1 && activeAddress[0] === i
                        ? "rotate(180deg)"
                        : "rotate(0)",
                  }}
                />
              </div>
            </div>
            <div>
              <div>
                {section.sections && section.sections.length > 0 && (
                  <SecondaryItems>
                    {section.sections.map((el, j) => (
                      <SecondaryItem
                        key={j}
                        onClick={() => setAddress([activeAddress[i], j])}
                        selected={
                          activeAddress.length >= 1 && activeAddress[1] === j
                        }
                      >
                        {el.label}
                      </SecondaryItem>
                    ))}
                  </SecondaryItems>
                )}
                {activeAddress.length === 2 &&
                activeAddress[0] === i &&
                section.sections[activeAddress[1]] &&
                section.sections[activeAddress[1]].inputs
                  ? section.sections[activeAddress[1]].inputs.map(
                      (input, j) => (
                        <ItemsWrapper key={j}>
                          <InputComponets idx={j} {...input} />
                        </ItemsWrapper>
                      )
                    )
                  : activeAddress.length === 1 &&
                    section.inputs &&
                    section.inputs.map((input, j) => (
                      <ItemsWrapper key={j}>
                        <InputComponets idx={j} {...input} />
                      </ItemsWrapper>
                    ))}
              </div>
            </div>
          </PrimaryItemWrapper>
        ))}
      </div>
      <div>{children}</div>
    </Wrapper>
  );
};

export default Configurator;
