import React, { useContext } from "react";
import { TemplateContext } from "../../../../context";
import {
  Tertiary as Wrapper,
  TertiaryItem as Item,
  TertiaryItemTitle as ItemTitle,
  TertiaryItemDescription as ItemDescription,
  TertiarySubitem as Subitem,
  TertiarySectionTitle as Title,
  TertiaryDescription as Description,
} from "./desktopConfigurator.styles";
import InputComponents from "../../InputComponents";

const TertiarySection = ({ address, label, description, sections, inputs }) => {
  const state = useContext(TemplateContext);
  const [primaryIdx, secondaryIdx] = address;
  const parent = state.data[primaryIdx].sections[secondaryIdx];

  const handleUpdateSection = (parent, section) => {
    if (state && state.configuration[parent.attribute] !== section.value) {
      if (section.validation) {
        const updateObj = section.override
          ? { ...section.override, [parent.attribute]: section.value }
          : { [parent.attribute]: section.value };
        const valid = section.validation(updateObj, state.configuration);
        if (!valid) return;
      }

      state.setAttribute(parent.attribute, section.value);
      if (
        section.override &&
        state.configuration[parent.attribute] !== section.value
      ) {
        for (const attribute in section.override) {
          state.setAttribute(attribute, section.override[attribute]);
        }
      }
    }
  };

  const handleUpdateInput = (validation, attribute, value) => {
    if (validation) {
      const valid = validation({ [attribute]: value }, state.configuration);
      if (!valid) return;
    }
    state.setAttribute(attribute, value);
  };

  return (
    <Wrapper className="scrollable">
      <Title>{label}</Title>
      {description && <Description>{description}</Description>}
      {sections &&
        sections.length > 0 &&
        Object.values(sections).map((section) => (
          <Item
            count={section.inputs ? section.inputs.length : 0}
            key={section.id}
            selected={
              !!(
                state && section.value === state.configuration[parent.attribute]
              )
            }
            onClick={() => handleUpdateSection(parent, section)}
          >
            <ItemTitle>
              <div>{section.label}</div>
            </ItemTitle>
            {section.description && (
              <ItemDescription>{section.description}</ItemDescription>
            )}
            {section.inputs &&
              section.inputs.length > 0 &&
              Object.values(section.inputs).map((input) => (
                <Subitem key={input.id}>
                  {input &&
                    (!input.dependOnAttribute ||
                      (Array.isArray(input.dependOnValue)
                        ? input.dependOnValue
                        : [input.dependOnValue]
                      ).includes(
                        state.configuration[input.dependOnAttribute]
                      )) && (
                      <InputComponents
                        {...input}
                        configuration={state.configuration[input.attribute]}
                        onChange={(value) =>
                          handleUpdateInput(
                            input.validation || null,
                            input.attribute,
                            value
                          )
                        }
                      />
                    )}
                </Subitem>
              ))}
          </Item>
        ))}
      {inputs &&
        inputs.length > 0 &&
        Object.values(inputs).map((input) => (
          <Subitem key={input.id}>
            {(!input.dependOnAttribute ||
              (Array.isArray(input.dependOnValue)
                ? input.dependOnValue
                : [input.dependOnValue]
              ).includes(state.configuration[input.dependOnAttribute])) && (
              <InputComponents
                {...input}
                configuration={state.configuration[input.attribute]}
                onChange={(value) =>
                  handleUpdateInput(
                    input.validation || null,
                    input.attribute,
                    value
                  )
                }
              />
            )}
          </Subitem>
        ))}
    </Wrapper>
  );
};

export default TertiarySection;
