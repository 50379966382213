import React, { useState, useContext, useEffect } from 'react';
import { TemplateContext } from '../../../../../context';
import { Title, Description } from '../../inputComponents.styles';
import {
  Wrapper,
  ButtonsRow,
  ButtonWrapper,
} from './radioButtonsImages.styles';
import icnImgMissing from '../../../../assets/no-image.webp';

const RadioButtonsImages = ({
  label,
  description,
  images,
  data,
  value,
  onChange,
}) => {
  const { getAssetsList } = useContext(TemplateContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      setOptions([]);
      if (data.source && data.source === 'platform') {
        const optionsList = await getAssetsList(data.tag);
        setOptions(optionsList);
      } else {
        setOptions(data.options);
      }
    })();
  }, [label]);

  return (
    <>
      {options.length > 0 && (
        <Wrapper>
          {label && (
            <Title>
              <div />
              <div>{label}</div>
            </Title>
          )}
          {description && <Description>{description}</Description>}
          <ButtonsRow>
            {options
              // .filter(
              //   option =>
              //     !option.dependOnAttribute ||
              //     state.configuration[option.dependOnAttribute] ===
              //       option.dependOnValue
              // )
              .map((option, i) => (
                <ButtonWrapper
                  key={i}
                  selected={option.value === value}
                  onClick={() => onChange(option.value)}
                >
                  <div>
                    <img src={icnImgMissing} alt="missing" />
                  </div>
                  <div>{option.label}</div>
                </ButtonWrapper>
              ))}
          </ButtonsRow>
        </Wrapper>
      )}
    </>
  );
};

export default RadioButtonsImages;
