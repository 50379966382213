import styled from "styled-components";

const height = {
  primaryItem: "70px",
  primarySubitem: "50px",
  secondaryItem: "40px",
  secondarySubitem: "100px",
};

export const width = {
  primary: "175px",
  secondary: "300px",
};

const fontSize = {
  primaryItem: "17px",
  secondaryItem: "16px",
  tertiaryTitle: "20px",
};

export const Wrapper = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: ${width.primary} ${width.secondary};
`;

export const Primary = styled.div`
  height: 100vh;

  background: #eeeeee;
`;

export const Secondary = styled.div`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  color: ${(props) => props.theme.secondary};
  background: ${(props) => props.theme.secondary};
`;

export const Tertiary = styled.div`
  padding: 12px;
  height: 100vh;
  overflow: scroll;

  color: ${(props) => props.theme.tertiary};
  background: ${(props) => props.theme.tertiary};
`;

export const PrimaryItem = styled.div`
  height: ${(props) =>
    props.expand && props.selected
      ? `calc(${height.primaryItem} + (${props.count} * ${height.primarySubitem}))`
      : height.primaryItem};
  overflow: hidden;
  border-radius: 3px;

  transition: all ${(props) => props.theme.transitionDuration};

  &:hover {
    & > div:nth-child(1) {
      ${(props) =>
        !props.selected &&
        `background: ${props.theme.primary}; 
          color: ${props.theme.secondary}`};
    }
  }

  & > div:nth-child(1) {
    padding: 0 10px;
    cursor: pointer;
    height: ${height.primaryItem};

    background: ${(props) => (props.selected ? "#eeee" : "white")};
    color: ${(props) =>
      props.selected ? props.theme.primary : props.theme.primary};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: ${(props) =>
      props.selected && props.count ? "0px" : "3px"};
    border-bottom-right-radius: ${(props) =>
      props.selected && props.count ? "0px" : "3px"};
    font-size: ${fontSize.primaryItem};
    letter-spacing: 1.5px;

    display: grid;
    grid-template-columns: max-content auto;

    & > div {
      width: 100%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      /* padding-left: 40px; */

      img {
        height: 40px;
        width: 40px;
      }
    }

    & > div:nth-child(1) {
      height: 40px;
    }
    & > div:nth-child(2) {
      height: 24px;
    }
  }
`;

export const SecondaryItem = styled.div`
  height: ${height.primarySubitem};
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  color: ${(props) =>
    props.selected ? props.theme.secondary : props.theme.secondary};
  background: ${(props) => props.theme.secondary};
  cursor: pointer;
  border-radius: 3px;

  display: grid;
  grid-template-columns: 24px auto;
  padding: 0 10px;
  grid-gap: 20px;

  font-size: ${fontSize.secondaryItem};
  letter-spacing: 1px;

  hr {
    height: 1px;
    background: ${(props) =>
      props.selected ? props.theme.secondary : props.theme.secondary};
    outline: none;
    border: none;
    transition: all ${(props) => props.theme.transitionDuration};
    width: ${(props) => (props.selected ? "100%" : "8px")};
  }

  &:hover {
    hr {
      width: 100%;
    }
  }

  & > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div:nth-child(1) {
    height: 17px;
  }

  & > div:nth-child(2) {
    height: 24px;
  }
`;

export const TertiaryItem = styled.div`
  max-height: ${(props) =>
    props.selected && props.count ? "600px" : height.secondaryItem};
  background: white;
  color: ${(props) => props.theme.tertiary};
  padding: 0 8px;
  border: ${(props) =>
    props.selected
      ? `3px solid ${props.theme.colors.selected}`
      : `1px solid ${props.theme.tertiary}`};
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  overflow: hidden;

  transition: all ${(props) => props.theme.transitionDuration};
`;

export const TertiaryItemTitle = styled.div`
  height: ${height.secondaryItem};

  font-size: 16px;
  margin-bottom: 10px;

  & > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
`;

export const TertiaryItemDescription = styled.div`
  margin-bottom: 20px;
`;

export const TertiarySubitem = styled.div`
  color: ${(props) => props.theme.tertiary};
  margin-bottom: 25px;
`;

export const TertiarySectionTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: ${fontSize.tertiaryTitle};
  font-weight: 600;
`;

export const TertiaryDescription = styled.div`
  margin-bottom: 20px;
`;
