import React, { useState, useContext, useEffect } from 'react';
import { TemplateContext } from '../../../../../context';
import { Title, Description } from '../../inputComponents.styles';
import {
  Wrapper,
  ButtonWrapper,
  ButtonsRow,
} from './radioButtonsImagesMulti.styles';
import assets from '../../../../../../assets';
import icnImgMissing from '../../../../assets/no-image.webp';

const RadioButtonsImagesMulti = ({
  label,
  description,
  data,
  value,
  onChange,
}) => {
  //   const { getAssetsList } = useContext(TemplateContext);
  //   const [sections, setSections] = useState([]);

  //   useEffect(() => {
  //     (async () => {
  //       setOptions([]);
  //     //   if (data.source && data.source === 'platform') {
  //         const optionsList = await getAssetsList(data.tag);
  //         setOptions(optionsList);
  //       } else {
  //         setOptions(data.options);
  //       }
  //     })();
  //   }, [label]);

  return (
    <>
      {data.sections.map((section, i) => (
        <Wrapper key={i}>
          {section.label && <Title>{section.label}</Title>}
          {section.description && (
            <Description>{section.description}</Description>
          )}
          <ButtonsRow>
            {section.options.map((option, j) => (
              <ButtonWrapper
                key={j}
                selected={option.value === value}
                onClick={() => onChange(option.value)}
              >
                <div>
                  <img
                    src={
                      option.img && assets[option.img]
                        ? assets[option.img]
                        : option.imgUrl || icnImgMissing
                    }
                    alt={option.label}
                  />
                  {/* <div>
                  <div />
                </div> */}
                </div>
                <div>{option.label}</div>
              </ButtonWrapper>
            ))}
          </ButtonsRow>
        </Wrapper>
      ))}
    </>
  );
};

export default RadioButtonsImagesMulti;
