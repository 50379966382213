import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import Provider from './context';

import SantiagoTemplate, { metadata as santiagoMetadata } from './Santiago';
import MontevideoTemplate, {
  metadata as montevideoMetadata,
} from './Montevideo';
import BogotaTemplate, { metadata as bogotaMetadata } from './Bogota';
import BrasiliaTemplate, { metadata as brasiliaMetadata } from './Brasilia';
// import LimaTemplate, { metadata as limaMetadata } from './Lima';
// import CaracasTemplate, { metadata as caracasMetadata } from './Caracas';
// import QuitoTemplate, { metadata as quitoMetadata } from './Quito';

const TemplateProvider = ({
  children,
  configuration,
  threekitConfig,
  general,
  themeConfig,
}) => {
  return (
    <Provider
      configuration={configuration}
      threekitConfig={threekitConfig}
      general={general}
    >
      <ThemeProvider theme={{ ...theme, ...themeConfig }}>
        {children}
      </ThemeProvider>
    </Provider>
  );
};

export const templates = {
  santiago: {
    ...santiagoMetadata,
    Component: ({ configuration, threekitConfig, general, themeConfig }) => (
      <TemplateProvider
        configuration={configuration}
        threekitConfig={threekitConfig}
        general={general}
        themeConfig={themeConfig}
      >
        <SantiagoTemplate />
      </TemplateProvider>
    ),
  },
  montevideo: {
    ...montevideoMetadata,
    Component: ({ configuration, threekitConfig, general, themeConfig }) => (
      <TemplateProvider
        configuration={configuration}
        threekitConfig={threekitConfig}
        general={general}
        themeConfig={themeConfig}
      >
        <MontevideoTemplate />
      </TemplateProvider>
    ),
  },
  bogota: {
    ...bogotaMetadata,
    Component: ({ configuration, threekitConfig, general, themeConfig }) => (
      <TemplateProvider
        configuration={configuration}
        threekitConfig={threekitConfig}
        general={general}
        themeConfig={themeConfig}
      >
        <BogotaTemplate />
      </TemplateProvider>
    ),
  },
  // lima: {
  //   ...limaMetadata,
  //   Component: ({ configuration, threekitConfig, general, themeConfig }) => (
  //     <TemplateProvider
  //       configuration={configuration}
  //       threekitConfig={threekitConfig}
  //       general={general}
  //       themeConfig={themeConfig}
  //     >
  //       <LimaTemplate />
  //     </TemplateProvider>
  //   ),
  // },
  brasilia: {
    ...brasiliaMetadata,
    Component: ({ configuration, threekitConfig, general, themeConfig }) => (
      <TemplateProvider
        configuration={configuration}
        threekitConfig={threekitConfig}
        general={general}
        themeConfig={themeConfig}
      >
        <BrasiliaTemplate />
      </TemplateProvider>
    ),
  },
  // caracas: {
  //   ...caracasMetadata,
  //   Component: ({ configuration, threekitConfig, general, themeConfig }) => (
  //     <TemplateProvider
  //       configuration={configuration}
  //       threekitConfig={threekitConfig}
  //       general={general}
  //       themeConfig={themeConfig}
  //     >
  //       <CaracasTemplate />
  //     </TemplateProvider>
  //   ),
  // },
  // quito: {
  //   ...quitoMetadata,
  //   Component: ({ configuration, threekitConfig, general, themeConfig }) => (
  //     <TemplateProvider
  //       configuration={configuration}
  //       threekitConfig={threekitConfig}
  //       general={general}
  //       themeConfig={themeConfig}
  //     >
  //       <QuitoTemplate />
  //     </TemplateProvider>
  //   ),
  // },
};

export const Santiago = templates.santiago.Component;
export const Montevideo = templates.montevideo.Component;
export const Bogota = templates.bogota.Component;
export const Brasilia = templates.brasilia.Component;
// export const Lima = templates.lima.Component;
// export const Caracas = templates.caracas.Component;
// export const Quito = templates.quito.Component;

const Template = props => {
  const {
    template,
    configuration,
    threekitConfig,
    general,
    themeConfig,
  } = props;
  if (
    !template ||
    !configuration ||
    !threekitConfig ||
    !general ||
    !themeConfig ||
    !templates[template]
  ) {
    console.log('missing something');
    return null;
  }
  const { Component } = templates[template];
  return (
    <Component
      configuration={configuration}
      threekitConfig={threekitConfig}
      general={general}
      themeConfig={themeConfig}
    />
  );
};

export default Template;
