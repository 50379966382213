import React from 'react';
import { Wrapper } from './brasilia.styles';

import Player from './Components/Player';
import Configurator from './Components/Configurator';
import Modal from '../../Components/Modal';
import Title from './Components/Title';
import Widgets from './Components/Widgets';

const Brasilia = () => {
  return (
    <>
      <Wrapper>
        <Configurator />
        <Player>
          <Title />
          {/* <AddToCart /> */}
        </Player>
        <Widgets />
      </Wrapper>
      <Modal />
    </>
  );
};

export const metadata = {
  name: 'Brasilia',
  layers: 2,
  inputs: ['radioButtons', 'slider'],
  features: ['bom'],
};

export default Brasilia;
