import styled from 'styled-components';
import { getColor } from '../../../../utils';

/****************************************************
  Constants
 ****************************************************/

const TRANSITION_DURATION = '0.3s';

export const MOBILE = {
  buttonSize: '50px',
};

const height = {
  contentItem: '42px',
  app: window.innerHeight + 'px',
};

/****************************************************
 Shared Styles
 ****************************************************/

/****************************************************
 Mobile Configurator Styles
 ****************************************************/

export const Toggle = styled.div`
  & > div:nth-child(1) {
    ${props => props.show && 'display: none;'}
    height: 50px;
    width: 50px;
    position: fixed;
    top: 90%;
    left: 30px;
    background: rgba(255, 255, 255, 0.85);

    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    cursor: pointer;

    border-radius: 50%;
    border: 1px solid gray;
    z-index: 7;

    & > div {
      width: max-content;
      position: absolute;
      top: 50%;
      left: 50%;

      transition: all ${TRANSITION_DURATION};
    }

    & > div:nth-child(1) {
      opacity: ${props => (props.expand ? '0' : '1')};
      transform: ${props =>
        props.expand
          ? 'translate(-50%, -50%) rotate(90deg)'
          : 'translate(-50%, -50%)'};
    }

    & > div:nth-child(2) {
      opacity: ${props => (props.expand ? '1' : '0')};
      transform: ${props =>
        props.expand
          ? 'translate(-50%, -50%)'
          : 'translate(-50%, -50%) rotate(-90deg)'};
    }
  }

  & > div:nth-child(2) {
    ${props => !props.show && 'display: none;'}

    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    height: 100vh;
    width: 100vw;
  }
`;

export const PrimarySectionWrapper = styled.div`
  position: fixed;
  left: 100%;
  top: 0;
  height: ${height.app};
  width: 270px;
  z-index: 5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  border-left: 1px solid #eeeeee;
  background: #4a7e9e;
  color: white;


  ${props => props.expand === 0 && 'transform: translateX(-220px);'}
  ${props =>
    props.expand === 1 && 'transform: translateX(calc(-220px - 50px - 50px));'}
  ${props =>
    props.expand === 2 && 'transform: translateX(calc(-220px - 50px - 50px));'}

    transition: all ${TRANSITION_DURATION};
    `;

export const SecondarySectionWrapper = styled.div`
  position: fixed;
  left: 100%;
  top: 0;
  height: ${height.app};
  width: calc(270px);
  z-index: 6;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  background: white;
  color: black;


  ${props =>
    props.expand === 1 && 'transform: translateX(calc(-220px - 50px));'}
  ${props =>
    props.expand === 'tertiary' &&
    'transform: translateX(calc(-220px - 50px));'}

    transition: all ${TRANSITION_DURATION};
`;

export const TertiarySectionWrapper = styled.div`
  position: fixed;
  left: 100%;
  top: 0;
  height: ${height.app};
  width: 220px;
  padding-top: 15px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  /* background: ${props => props.theme.mobile.tertiary.defaultColor};
  color: ${props => props.theme.mobile.tertiary.fontDefaultColor}; */
  background: lightgrey;
  color: black;

  ${props => props.expand === 'tertiary' && 'transform: translateX(-220px);'}

  z-index: 7;

  transition: all ${TRANSITION_DURATION};
`;

export const PrimaryItem = styled.div`
  height: ${MOBILE.buttonSize};
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 5px;

  cursor: pointer;
  
  & > div:nth-child(1) {
    height: ${MOBILE.buttonSize};
    width: ${MOBILE.buttonSize};
    padding: 3px;

    & > div {
      width: 90%;
      height: 90%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      font-size: 18px;
      ${props => props.selected && `background: grey;`}
      transition: all ${TRANSITION_DURATION};

      & > div {
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        img {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  & > div:nth-child(2) {
    height: ${MOBILE.buttonSize};

    & > div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const SectionContent = styled.div`
  width: 270px;
  padding: 8px 12px;
`;

export const SecondaryItem = styled(PrimaryItem)`
  color: ${props =>
    props.selected
      ? props.theme.mobile.secondary.fontSelectedColor
      : props.theme.mobile.secondary.fontDefaultColor};
  & > div:nth-child(1) > div {
    ${props =>
      props.selected &&
      `background: ${props.theme.mobile.secondary.selectedColor};`}
  }
`;

export const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
`;

export const TertiaryItem = styled.div`
  max-height: ${props => (props.selected ? `400px` : height.contentItem)};
  border: ${props =>
    props.selected
      ? `2px solid ${props.theme.colors.selected}`
      : '1px solid #e4e4e4'};
  border-radius: 4px;
  margin: 0 8px 12px 8px;
  ${props => props.selected && 'color: #112ed2;'}
  cursor: pointer;
  overflow: hidden;

  transition: all ${props => props.theme.transitionDuration};

  & > div:nth-child(1) {
    height: ${height.contentItem};

    & > div:nth-child(1) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
    }
  }
`;

export const TertiarySubitem = styled.div`
  /* height: max-content; */
  padding: 25px 12px;
  color: ${props => props.theme.mobile.tertiary.fontDefaultColor};
`;

export const SecondaryContentItem = styled.div`
  max-height: ${props => (props.selected ? '400px' : height.contentItem)};
  border: ${props =>
    props.selected
      ? `2px solid ${props.theme.colors.selected}`
      : '1px solid #e4e4e4'};
  border-radius: 4px;
  margin-bottom: 12px;
  background: white;
  cursor: pointer;
  overflow: hidden;

  transition: all ${TRANSITION_DURATION};
`;

export const SecondaryContentItemTitle = styled.div`
  height: ${height.contentItem};

  font-size: 14px;
  margin-bottom: 10px;

  & > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
`;

export const SecondaryContentSubitem = styled.div`
  padding: 0 8px;
`;
