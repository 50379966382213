import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../constants';
import { getColor } from '../../../utils';

export const Title = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 7px;

  & > div:nth-child(1) {
    width: 5px;
    background: ${props => getColor(props.theme, 'primary', 5)};
  }

  @media ${MEDIA_QUERY.mobile} {
    font-size: 14px;
    margin-bottom: 8px;
  }

  @media ${MEDIA_QUERY.tabletP} {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const Description = styled.div`
  margin-bottom: 12px;
`;
