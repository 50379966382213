import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Wrapper, Field } from './modal.styles';
import { TemplateContext } from '../../Templates/context';
import moment from 'moment';
import { Input, DatePicker, Button, message } from 'antd';
import ErrorMessage from './ErrorMessage';
import Joi from 'joi';
import validator from 'validator';

const userDataSchema = Joi.object({
  projectName: Joi.string().min(3).max(30).required(),
  name: Joi.string().min(3).max(30).required(),
  email: Joi.string().email({ minDomainSegments: 2, tlds: false }).required(),
  phone: Joi.number().integer(),
}).unknown(true);

const Modal = () => {
  const { showModal, setShowModal, handleAddToCart } = useContext(
    TemplateContext
  );

  const [projectName, setProjectName] = useState('');
  const [requiredBy, setRequiredBy] = useState(moment().add(4, 'weeks'));
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [requestPending, setRequestPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClickSubmit = async () => {
    const userData = {
      projectName,
      requiredBy: requiredBy.format('Do MMM YYYY'),
      date: moment().format('Do MMM YYYY'),
      name,
      address,
      contact,
      phone,
      email,
    };

    const validation = userDataSchema.validate(userData);
    if (validation.error)
      return message.warning(validation.error.details[0].message);

    if (requiredBy.diff(moment(), 'days') < 27)
      return message.warning(
        'Required By: Please allow 4 weeks for processing'
      );

    setRequestPending(true);
    if (await handleAddToCart(userData)) {
      setProjectName('');
      setRequiredBy(moment().add(3, 'weeks'));
      setName('');
      setAddress('');
      setContact('');
      setPhone('');
      setEmail('');
      setShowModal(false);
      message.success('Estimate submitted.');
    } else {
      message.warning('Opps! Something went wrong.');
    }
    setRequestPending(false);
  };

  const handleClickCancel = () => {
    setProjectName('');
    setRequiredBy(moment().add(4, 'weeks'));
    setName('');
    setAddress('');
    setContact('');
    setPhone('');
    setEmail('');
    setShowModal(false);
    setRequestPending(false);
  };

  const validateEmail = email => {
    return validator.isEmail(email);
  };
  const validatePhone = phone => {
    return validator.isMobilePhone(phone);
  };

  const validate = () => {
    let message = '';
    if (!validatePhone(phone)) {
      message = 'Invalid phone number';
    } else if (!validateEmail(email)) {
      message = 'Invalid email';
    }
    setErrorMessage(message);
  };

  if (!showModal) return null;

  let isDisabled = false;
  if (
    projectName.length < 3 ||
    name.length < 3 ||
    address.length < 3 ||
    contact.length < 3 ||
    !validatePhone(phone) ||
    !validateEmail(email)
  )
    isDisabled = true;

  return ReactDOM.createPortal(
    <Wrapper onClick={() => setShowModal(false)}>
      <div onClick={e => e.stopPropagation()}>
        <div>Get Configuration Estimate</div>
        <div>
          <Field>
            <div>Project Name:</div>
            <div>
              <Input
                value={projectName}
                onChange={e => setProjectName(e.target.value)}
              />
            </div>
          </Field>
          <Field>
            <div>Required By:</div>
            <div>
              <DatePicker
                value={requiredBy}
                disabledDate={cur => cur.diff(moment(), 'days') < 27}
                onChange={(date, datestring) => setRequiredBy(date)}
              />
            </div>
          </Field>
          <Field>
            <div>Name:</div>
            <div>
              <Input value={name} onChange={e => setName(e.target.value)} />
            </div>
          </Field>
          <Field>
            <div>Address:</div>
            <div>
              <Input
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
          </Field>
          <Field>
            <div>Project Contact:</div>
            <div>
              <Input
                value={contact}
                onChange={e => setContact(e.target.value)}
              />
            </div>
          </Field>
          <Field>
            <div>Phone:</div>
            <div>
              <Input
                value={phone}
                type="tel"
                onChange={e => setPhone(e.target.value)}
                onBlur={() => validate()}
              />
            </div>
          </Field>
          <Field>
            <div>E-mail:</div>
            <div>
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                onBlur={() => validate()}
              />
            </div>
          </Field>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : ''}
        </div>
        <div>
          <Button onClick={handleClickCancel} style={{ marginRight: 12 }}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={requestPending}
            disabled={isDisabled}
            onClick={handleClickSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Wrapper>,
    document.getElementById('modal')
  );
};

export default Modal;
