import styled from "styled-components";
import { MEDIA_QUERY } from "../constants";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: max-content auto;

  @media ${MEDIA_QUERY.mobile} {
    height: ${window.innerHeight}px;
    display: block;
  }
`;
