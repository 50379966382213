import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../../constants';
import { getColor } from '../../../../utils';

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 10px;

  padding-left: 4px;
  margin-bottom: 20px;

  @media ${MEDIA_QUERY.mobile} {
    & > div:nth-child(2) {
      display: none;
    }
  }

  .ant-slider-handle {
    border: 2px solid ${props => getColor(props.theme, 'primary', 2)};
  }

  .ant-slider-track {
    background-color: ${props => getColor(props.theme, 'primary', 2)};
  }

  .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px ${props => getColor(props.theme, 'primary', 2)}22;
  }

  .ant-slider:hover {
    .ant-slider-handle {
      border: 2px solid ${props => getColor(props.theme, 'primary', 2)};
    }

    .ant-slider-track {
      background-color: ${props => getColor(props.theme, 'primary', 2)};
    }
  }
`;
