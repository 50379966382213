import React from 'react';
import 'antd/dist/antd.css';
import { useMediaQuery } from 'react-responsive';
import Templates from './Templates';

import templateData from './template-data.json';

const App = () => {
  const { configurator, threekitConfig, general, themeConfig } = templateData;
  const isDesktop = useMediaQuery({
    // query: '(min-device-width: 600px)',
    query: '(min-width: 600px)',
  });

  return (
    <Templates
      template={isDesktop ? 'santiago' : 'brasilia'}
      // icons={icons}
      configuration={configurator}
      threekitConfig={threekitConfig}
      general={general}
      themeConfig={themeConfig}
    />
  );
};

export default App;
