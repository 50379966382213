import React from 'react';
import { Wrapper } from './notes.style';

const Notes = () => {
  return (
    <Wrapper>
      <div>Non-visualized</div>
    </Wrapper>
  );
};
export default Notes;
